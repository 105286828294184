import { createSlice } from '@reduxjs/toolkit';
import { BankState } from 'redux/bank/bank.type';
import { BankApi } from 'api';
import { addFlagIconsWallets } from 'utils/common';

const initialState: BankState = {
  isLoading: false,
  isWesternUniLoading: false,
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    reset: () => {
      return {
        isLoading: false,
        isWesternUniLoading: false,
        bankError: undefined,
      };
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setBankError: (state, { payload }) => {
      state.bankError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BankApi.getWesternUnionBankMethodData.pending, (state) => {
        state.isWesternUniLoading = true;
        state.westernUnionBankMethodData = undefined;
      })
      .addCase(BankApi.getWesternUnionBankMethodData.fulfilled, (state, action) => {
        state.westernUnionBankMethodData = action.payload;
        state.isWesternUniLoading = false;
      })
      .addCase(BankApi.getWesternUnionBankMethodData.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isWesternUniLoading = false;
      })
      .addCase(BankApi.connectCompanyWebhook.pending, (state) => {
        state.connectCompanyResponse = undefined;
        state.isLoading = true;
      })
      .addCase(BankApi.connectCompanyWebhook.fulfilled, (state, action) => {
        state.connectCompanyResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.connectCompanyWebhook.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.getStripeCompanyAccountPlatform.pending, (state, action) => {
        state.accountSystemData = undefined;
        state.isLoading = true;
      })
      .addCase(BankApi.getStripeCompanyAccountPlatform.fulfilled, (state, action) => {
        state.accountSystemData = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.getStripeCompanyAccountPlatform.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.getConnectedBankAccountList.pending, (state, action) => {
        state.bankInformationData = undefined;
        state.isLoading = true;
      })
      .addCase(BankApi.getConnectedBankAccountList.fulfilled, (state, action) => {
        state.bankInformationData = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.getConnectedBankAccountList.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isLoading = false;
      })

      .addCase(BankApi.getWalletWesternUnion.pending, (state) => {
        state.walletWesternUnionData = undefined; // TODO
      })
      .addCase(BankApi.getWalletWesternUnion.fulfilled, (state, action) => {
        state.walletWesternUnionData = addFlagIconsWallets(action.payload);
      })
      .addCase(BankApi.getWalletWesternUnion.rejected, (state, action) => {
        state.bankError = action.payload;
      })
      .addCase(BankApi.getPlaidConfiguration.pending, (state) => {
        state.isLoading = true;
        state.plaidConfig = undefined;
      })
      .addCase(BankApi.getPlaidConfiguration.fulfilled, (state, action) => {
        state.plaidConfig = action.payload;
        state.isLoading = true;
      })
      .addCase(BankApi.getPlaidConfiguration.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isLoading = false;
      })
      .addCase(BankApi.getWesternUnionBankingOptions.pending, (state) => {
        state.isLoading = true;
        state.bankOptions = undefined;
      })
      .addCase(BankApi.getWesternUnionBankingOptions.fulfilled, (state, action) => {
        state.bankOptions = action.payload;
        state.isLoading = true;
      })
      .addCase(BankApi.getWesternUnionBankingOptions.rejected, (state, action) => {
        state.bankError = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setIsLoading, setBankError, reset } = bankSlice.actions;

export default bankSlice.reducer;
