import axios from 'axios';
import { ServerErrors, ServerErrorSeverity } from '../redux/common/common.type';

const axiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  // withCredentials: true,
});

// TODO add cookie
// axiosInstance.defaults.xsrfCookieName = 'csrftoken';
// axiosInstance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
axiosInstance.interceptors.request.use(
  (req) => {
    if (!!req.headers) {
      req.headers.apikey = process.env.REACT_APP_SERVER_APIKEY;
      req.headers.csrftoken = document.cookie;
    } else {
      req.headers = { apikey: process.env.REACT_APP_SERVER_APIKEY, csrftoken: document.cookie };
    }
    return req;
  },
  (error) => Promise.reject(error)
);

export const handleServerErrors = (
  error: any,
  serviceName?: string,
  severity?: ServerErrorSeverity
): ServerErrors => {
  if (!error.response) {
    return {
      status: 500,
      error: {
        message: error.message,
        message_header: error.code,
      },
      severity: severity,
    };
  }
  return {
    error: !!error.response?.data.error
      ? error.response?.data.error
      : {
          message: error.response?.data?.detail || error.message,
          message_header: serviceName ? serviceName : error.response.statusText,
        },
    status: error.response?.status,
    severity: severity,
    detail: error.response?.data?.detail || error.message,
  };
};

export default axiosInstance;
