// TODO review it
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SupportedCurrencies,
  ServerErrors,
  AllCurrencies,
  StateData,
  CountryListData,
  ActionNeededData,
  GrossProfitData,
  QuickActionData,
  VisitedPagePayload,
  TilesData,
  VisitedPageData,
  SellerProfileDetailsData,
  UpdateFlagsData,
  SearchResponse,
} from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';
import {
  BlogResponse,
  CommonSuccessResponse,
  MultipleCompanyAddress,
} from '../redux/common/common.type';

// TODO review it, check response
const getFileByUrl = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getFileByUrl',
  async (url, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(url);
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review
const getRefreshCodat = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getRefreshCodat',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(`${process.env.REACT_APP_SERVER_URL}tpii/codat/sync/`);
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review if we can drop it
const getAllCurrencies = createAsyncThunk<AllCurrencies[], void, { rejectValue: ServerErrors }>(
  'getAllCurrencies',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<AllCurrencies[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/all-currencies/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check if we can drop it
const getCountriesList = createAsyncThunk<CountryListData, string, { rejectValue: ServerErrors }>(
  'getCountriesList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<CountryListData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/countries-list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check if we can drop it
const uploadDocument = createAsyncThunk<
  CommonSuccessResponse,
  FormData,
  { rejectValue: ServerErrors }
>('uploadDocument', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/upload_document/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getActionNeeded = createAsyncThunk<ActionNeededData, void, { rejectValue: ServerErrors }>(
  'getActionNeeded',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<ActionNeededData>(
        `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/invoice/action_needed/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getFBQuickActions = createAsyncThunk<QuickActionData, string, { rejectValue: ServerErrors }>(
  'getFBQuickActions',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<QuickActionData>(
        `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/invoice/fb_action_needed/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getGrossProfit = createAsyncThunk<GrossProfitData, string, { rejectValue: ServerErrors }>(
  'getGrossProfit',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<GrossProfitData>(
        `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/gross_profit/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getQuickActions = createAsyncThunk<QuickActionData, string, { rejectValue: ServerErrors }>(
  'getQuickActions',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<QuickActionData>(
        `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/invoice/quick-actions/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO why it's needed when we already know the currency?,
const getSelectedCurrency = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getSelectedCurrency',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/get_currency/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const updateCountryState = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'updateCountryState',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/save_country_state/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getMultipleCompanyList = createAsyncThunk<
  MultipleCompanyAddress[],
  string,
  { rejectValue: ServerErrors }
>('getMultipleCompanyList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<MultipleCompanyAddress[]>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/multiple_company_list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const verifyCompany = createAsyncThunk<CommonSuccessResponse, any, { rejectValue: ServerErrors }>(
  'verifyCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<CommonSuccessResponse>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/multiple_company/verify/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const companyNotShown = createAsyncThunk<
  CommonSuccessResponse,
  { params: string; payload: { id: number } },
  { rejectValue: ServerErrors }
>('companyNotShown', async ({ params, payload }, { rejectWithValue }) => {
  try {
    //const { params, payload } = payloadData;
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/multiple_company/not_sure/${params}`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getGlobalSearchValue = createAsyncThunk<
  SearchResponse,
  string,
  { rejectValue: ServerErrors }
>('getGlobalSearchValue', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SearchResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/global_search/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSellerProfileDetails = createAsyncThunk<
  SellerProfileDetailsData,
  string,
  { rejectValue: ServerErrors }
>('getSellerProfileDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SellerProfileDetailsData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/seller_profile/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const saveStickyNotes = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveStickyNotes',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/sticky_note_details/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const geStateUrl = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'geStateUrl',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/state-urls/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const getPagesVisited = createAsyncThunk<
  VisitedPageData,
  VisitedPagePayload,
  { rejectValue: ServerErrors }
>('getPagesVisited', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<VisitedPageData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/pages_visited/`,
      {
        ...payload,
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const updateFlags = createAsyncThunk<any, UpdateFlagsData, { rejectValue: ServerErrors }>(
  'saveStickyNotes',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<UpdateFlagsData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/customer_config_details/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getBlogs = createAsyncThunk<BlogResponse, void, { rejectValue: ServerErrors }>(
  'getBlogs',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<BlogResponse>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/crowdz/blog/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getTilesData = (type: string) =>
  createAsyncThunk<TilesData, string, { rejectValue: ServerErrors }>(
    `getTilesData${type}`,
    async (params, { rejectWithValue }) => {
      try {
        const { data } = await axios.get<TilesData>(
          `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/get_tiles_data/${params}`
        );
        return data;
      } catch (err) {
        return rejectWithValue(handleServerErrors(err));
      }
    }
  );

const getCurrency = (values?: SupportedCurrencies[], defaultCurrency?: string) => {
  if (Array.isArray(values) && values.length > 0) {
    const tmp = values.filter((f) => !!f.id);
    const cObj = tmp.find((f) => f.is_default);
    if (!!cObj) return cObj.alpha_3_code;
    if (!!tmp[0]) return tmp[0].alpha_3_code as string;
  }
  return defaultCurrency;
};

const getSupportedCurrencies = createAsyncThunk<
  SupportedCurrencies[],
  string,
  { rejectValue: ServerErrors }
>(`getSupportedCurrencies`, async (type, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SupportedCurrencies[]>(
      `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/supported_currency/list/?receivable_type=${type}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO delete
const getSupportedCurrenciesAndTiles = (receivable_type: string) =>
  createAsyncThunk<SupportedCurrencies[], string, { rejectValue: ServerErrors }>(
    `getSupportedCurrencies${receivable_type}`,
    async (currency, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.get<SupportedCurrencies[]>(
          `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/supported_currency/list/?receivable_type=${receivable_type}`
        );
        dispatch(
          getTilesData(receivable_type)(
            `?receivable_type=${receivable_type}&currency=${getCurrency(data, currency)}`
          )
        );
        return data;
      } catch (err) {
        return rejectWithValue(handleServerErrors(err));
      }
    }
  );

const getSupportedCurrenciesForInvoice = createAsyncThunk<
  SupportedCurrencies[],
  string,
  { rejectValue: ServerErrors }
>(`getSupportedCurrenciesForInvoice`, async (type, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await axios.get<SupportedCurrencies[]>(
      `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/supported_currency/list/invoice/${type}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getStateData = createAsyncThunk<StateData, string, { rejectValue: ServerErrors }>(
  `getStateData`,
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<StateData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/get_states/?limit=1000&country_code=${code}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO language api

export default {
  getSupportedCurrencies,
  getRefreshCodat,
  getFileByUrl,
  getStateData,
  getCountriesList,
  getAllCurrencies,
  getPagesVisited,
  geStateUrl,
  uploadDocument,
  getActionNeeded,
  getQuickActions,
  getFBQuickActions,
  saveStickyNotes,
  getSellerProfileDetails,
  getGlobalSearchValue,
  companyNotShown,
  verifyCompany,
  getMultipleCompanyList,
  updateCountryState,
  getSelectedCurrency,
  getGrossProfit,
  getTilesData,
  updateFlags,
  getBlogs,
  getSupportedCurrenciesAndTiles,
  getSupportedCurrenciesForInvoice,
};
