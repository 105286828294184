import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import Typography from '@mui/material/Typography';
import FormikTextField from 'components/formik/FormikTextField';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { CompanyValidationPayload } from 'redux/userManagement/userManagement.type';

interface OwnProps {
  onSubmit: (value: Partial<CompanyValidationPayload>) => void;
  init: Partial<CompanyValidationPayload>;
}

const ValidationSchema = Yup.object().shape({
  company_name: Yup.string().required('Required'),
});

const CompanyLegalNameRegStep = ({ onSubmit, init }: OwnProps) => {
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} autoComplete="off">
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={'30px'}
              >
                What is your company's legal name?
              </Typography>
              <Field
                name={`company_name`}
                type="text"
                required
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Legal Name</span>}
                component={FormikTextField}
                fontSize={'13px'}
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyLegalNameRegStep;
