import { useEffect, useState } from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/store';
import { DASHBOARD_PAGES, SETTINGS_PAGES } from 'routes/paths';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { Drawer, styled } from '@mui/material';
import { UserNotificationApi } from 'api';
import {
  ExtraData,
  UserNotificationData,
  NotificationResultData,
} from 'redux/userNotification/userNotification.type';
import notification from 'redux/slices/notification';
import { Warning } from '@mui/icons-material';

const StyledPopHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 15px 10px 15px',
  fontSize: '12px',
}));
const StyledDescrip = styled('div')(({ theme }) => ({
  paddingLeft: '1em',
  fontSize: '12px',
}));

export default function NotificationsPopover() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // this.notificTableInfo.itemsPerPage = 5;
  // this.notificTableInfo.offSetValue = 0;

  // this.notificTableInfo.currentPage =  1;
  const { userNotifications } = useSelector((state) => state.userNotification);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const [notificationTableInfo, setNotificationTableInfo] = useState<any>({
    offSetValue: 0,
    itemsPerPage: 5,
    updatedItemPerPage: 10,
    currentPage: 1,
  });
  const [isViewAll, setViewAll] = useState<boolean>(false);
  const [isAllShow, setAllShow] = useState<boolean>(false);
  const [notificationList, setNotificationList] = useState<NotificationResultData[]>([]);
  const [notifications, setNotifications] = useState<any>({
    messages: [],
    count: 0,
    totalCount: 0,
  });
  const [paginationParam, setPaginationParam] = useState<any>('');
  const [unreadValue, setUnreadValue] = useState<number>(0);

  useEffect(() => {
    // TODO next
    getNotificationList();
    // https://exchange-demo.crowdz.io/api/invoice-exchange/notification/list/?limit=5&offset=0
    // https://exchange-demo.crowdz.io/api/invoice-exchange/notification/list/?type=warning
    // https://exchange-demo.crowdz.io/api/invoice-exchange/notification/list/?type=success
  }, []);

  const getNotificationList = async (from?: any) => {
    // dispatch(UserNotificationApi.getNotification(`?limit=${0}&offset=${5}`));
    // console.log("Notifications", userNotifications)
    if (from === 'view-more') {
      setTimeout(() => {
        setNotificationTableInfo((prevState: any) => ({
          ...prevState,
          itemsPerPage: notificationTableInfo.itemsPerPage + 5,
        }));
      }, 100);
      console.log('UPDATE', notificationTableInfo?.itemsPerPage);
      setPaginationParam(
        `?limit=${notificationTableInfo.itemsPerPage}&offset=${notificationTableInfo.offSetValue}`
      );
    } else {
      setPaginationParam(
        `?limit=${notificationTableInfo.itemsPerPage}&offset=${notificationTableInfo.offSetValue}`
      );
    }
    let notificationResponse = await dispatch(UserNotificationApi.getNotification(paginationParam));
    if (UserNotificationApi.getNotification.fulfilled.match(notificationResponse)) {
      console.log('notificationResponse', notificationResponse);
      setNotificationList(notificationResponse?.payload?.results);
      if (from === 'view-more') {
        setNotifications({ messages: notifications?.messages?.concat(notificationList) });
      } else {
        setNotifications({ messages: notificationList });
      }
      if (from === 'read-all') {
        setUnreadValue(0);
      } else {
        setUnreadValue(0);
        setUnreadValue(notificationResponse?.payload?.count);
      }
      setNotifications({
        count: notificationResponse?.payload?.count,
        totalCount: notificationResponse?.payload?.total_count,
      });
    }
  };

  const displaySecondMenu = (e: any) => {
    setAllShow(true);
    setNotificationTableInfo((prevState: any) => ({
      ...prevState,
      offSetValue: notifications?.messages?.length,
    }));
    getNotificationList('view-more');
    setNotificationTableInfo((prevState: any) => ({
      ...prevState,
      currentPage: notificationTableInfo.currentPage + 1,
    }));
  };

  const clearAll = (type?: any) => {
    setAllShow(false);
    dispatch(UserNotificationApi.editAllNotifications());
    getNotificationList(type);
  };

  // TODO improve it
  //TODO Warning notification list(popover)

  // "Incomplete Buyer Address" = "redirect_url": "ineligible_tab",
  // "Wallet Inactive For 80 Days"= "redirect_url"	NO
  // "Low Wallet Balance"= "redirect_url"	NO
  // "Congratulations! Your account is all set up."= "redirect_url"	NO
  // "Action needed"	"redirect_url": "/v2/account/banking-information"
  // "Manual Repayment" = "redirect_url"	NO
  // "Data Policy"	"redirect_url": "https://free.facebook.com/privacy/policy/#1"
  // "Wallet Inactive For 90 Days"	= "redirect_url"NO
  // "Information"= "redirect_url"	NO
  // "Offer Accepted!"	= "redirect_url"NO
  // "Changes are coming"	= "redirect_url"NO
  // "Manual Repayment Beneficiary Details Changed" = "redirect_url"	NO

  const getLink = (v: ExtraData) => {
    if (!!v.redirect_url && v.redirect_url === 'needs_verification') {
      return `${DASHBOARD_PAGES.marketplace}/invoice/list?invoice_id=${v.q}&redirect_url=${v.redirect_url}`;
    } else if (!!v.redirect_url && v.redirect_url === 'dashboard') {
      return `${DASHBOARD_PAGES.dashboard}/dashboard`;
    } else if (!!v.redirect_url && v.redirect_url === 'sell_invoice') {
      return `${DASHBOARD_PAGES.marketplace}/sell-invoice?searchText=${v.q}&invoiceType=${v.filter}`;
    } else if (!!v.redirect_url && v.redirect_url === 'invoice') {
      return `${DASHBOARD_PAGES.marketplace}/invoice/list?invoice_id=${v.q}`;
    } else if (!!v.redirect_url && v.redirect_url === 'buy_invoice') {
      return `${DASHBOARD_PAGES.marketplace}/buy-invoice?searchText=${v.q}&invoiceType=${v.filter}`;
    } else if (!!v.redirect_url && v.redirect_url === 'repayment') {
      return `${DASHBOARD_PAGES.repayment}/v2/repayment/list?invoiceSearch=${v.q}&invoiceType=${v.filter}`;
    } else if (!!v.redirect_url && v.redirect_url === 'history') {
      return `${DASHBOARD_PAGES.history}/history?invoiceSearch=${v.q}`;
    } else if (!!v.redirect_url && v.redirect_url === 'document_library') {
      return `${SETTINGS_PAGES.documentLibrary}/v2/account/document-library?defaultState=${4}`;
    }
    return '';
  };
  // TODO

  const openBulkUploadErrorModal = (v: NotificationResultData) => {};
  // TODO review use count instead
  const unreadNotifications =
    userNotifications && Array.isArray(userNotifications.results)
      ? userNotifications.results.filter((f) => f.unread)
      : [];
  // TODO check icon [class]="isAllShow === false ? 'notification-icon':'all-notification notification-icon
  return (
    <div style={{ marginRight: '8px' }}>
      <Badge badgeContent={unreadValue > 100 ? '100+' : unreadValue} color="error">
        <NotificationsActiveOutlinedIcon
          sx={{
            fontSize: '28px',
            ml: 0.2,
          }}
          onClick={handleClick}
        />
      </Badge>
      {isAllShow === false && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ borderRadius: '10px' }}
        >
          <StyledPopHeader>
            <Typography fontSize={'12px'} fontWeight={(theme) => theme.typography.fontWeightBold}>
              Notifications
            </Typography>
            {unreadValue !== 0 && (
              <Link
                // href="#"
                underline="none"
                sx={{
                  color: (theme) => theme.palette.error.light,
                  fontWeight: (theme) => theme.typography.fontWeightMedium,
                }}
                onClick={() => clearAll('read=all')}
              >
                Mark all as read {unreadValue > 100 ? '100+' : unreadValue}
              </Link>
            )}
          </StyledPopHeader>
          <Divider sx={{ width: '100%' }} />
          <Grid container sx={{ maxWidth: '250px', padding: 2 }}>
            {notificationList.map((notification, index) => {
              const extraInfo = getLink(notification.extra_data);
              return (
                <Grid item key={index} onClick={handleClose}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon
                      sx={{
                        width: '10px',
                        height: '10px',
                        marginRight: '3px',
                        color: (theme) => theme.palette.primary.main,
                      }}
                    />
                    <Typography
                      variant="body1"
                      fontSize="12px"
                      fontWeight={(theme) => theme.typography.fontWeightBold}
                    >
                      {notification.verb}
                    </Typography>
                  </div>
                  <StyledDescrip>
                    {notification.description}
                    {extraInfo && (
                      <Link
                        sx={{ paddingLeft: '4px', textDecoration: 'capitalize' }}
                        onClick={() => console.log('TODO')}
                      >
                        to do
                      </Link>
                    )}
                  </StyledDescrip>
                  {notification.extra_data?.error_data &&
                    notification?.extra_data?.error_data.length > 0 && (
                      <>
                        <Typography variant="body1">
                          Some invoices are not uploaded due to error.
                          <Link onClick={() => openBulkUploadErrorModal(notification)}>
                            Click here
                          </Link>
                          to view errors.
                        </Typography>
                        <Typography variant="body1">{notification.time_since}</Typography>
                      </>
                    )}
                  {index < unreadNotifications.length - 1 && (
                    <>
                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                      {/* <Typography>View More Notifications</Typography> */}
                    </>
                  )}
                  {/* <If="isViewAll && notifications?.count != notifications?.messages?.length" (click)="displaySecondMenu($event)">
                        <span>View More Notifications({{notifications?.count - notifications?.messages?.length}})</span>
                    </If=> */}
                </Grid>
              );
            })}
            {notifications?.total_count !== notifications?.messages?.length && (
              <>
                <Divider sx={{ width: '100%' }} />
                <Grid container sx={{ maxWidth: '250px' }}>
                  <Grid item sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Link
                      underline="none"
                      sx={{
                        fontSize: '14px',
                        fontWeight: (theme) => theme.typography.fontWeightMedium,
                      }}
                      onClick={(e) => displaySecondMenu(e)}
                    >
                      View More Notifications (
                      {notifications?.total_count - notifications?.messages?.length})
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
            {notifications?.messages?.length === 0 && <Typography>No notifications</Typography>}
          </Grid>
        </Popover>
      )}
      {isAllShow === true && (
        <Drawer open={open} onClose={handleClose} anchor="right">
          <StyledPopHeader>
            <Typography fontSize={'12px'} fontWeight={(theme) => theme.typography.fontWeightBold}>
              Notifications
            </Typography>
            {unreadValue !== 0 && (
              <Link
                // href="#"
                underline="none"
                sx={{
                  color: (theme) => theme.palette.error.light,
                  fontWeight: (theme) => theme.typography.fontWeightMedium,
                }}
                onClick={() => clearAll('read=all')}
              >
                Mark all as read {unreadValue > 100 ? '100+' : unreadValue}
              </Link>
            )}
          </StyledPopHeader>
          <Divider sx={{ width: '100%' }} />
          <Grid container sx={{ maxWidth: '250px', padding: 2 }}>
            {notificationList.map((notification, index) => {
              const extraInfo = getLink(notification.extra_data);
              return (
                <Grid item key={index} onClick={handleClose}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon
                      sx={{
                        width: '10px',
                        height: '10px',
                        marginRight: '3px',
                        color: (theme) => theme.palette.primary.main,
                      }}
                    />
                    <Typography
                      variant="body1"
                      fontSize="12px"
                      fontWeight={(theme) => theme.typography.fontWeightBold}
                    >
                      {notification.verb}
                    </Typography>
                  </div>
                  <StyledDescrip>
                    {notification.description}
                    {extraInfo && (
                      <Link
                        sx={{ paddingLeft: '4px', textDecoration: 'capitalize' }}
                        onClick={() => console.log('TODO')}
                      >
                        to do
                      </Link>
                    )}
                  </StyledDescrip>
                  {notification.extra_data?.error_data &&
                    notification?.extra_data?.error_data.length > 0 && (
                      <>
                        <Typography variant="body1">
                          Some invoices are not uploaded due to error.
                          <Link onClick={() => openBulkUploadErrorModal(notification)}>
                            Click here
                          </Link>{' '}
                          to view errors.
                        </Typography>
                        <Typography variant="body1">{notification.time_since}</Typography>
                      </>
                    )}
                  {index < unreadNotifications.length - 1 && (
                    <>
                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                      {/* <Typography>View More Notifications</Typography> */}
                    </>
                  )}
                  {/* <If="isViewAll && notifications?.count != notifications?.messages?.length" (click)="displaySecondMenu($event)">
                        <span>View More Notifications({{notifications?.count - notifications?.messages?.length}})</span>
                    </If=> */}
                </Grid>
              );
            })}
            {notifications?.total_count !== notifications?.messages?.length && isAllShow === true && (
              <>
                <Divider sx={{ width: '100%' }} />
                <Link
                  underline="none"
                  sx={{
                    fontSize: '14px',
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                  }}
                  onClick={(e) => displaySecondMenu(e)}
                >
                  View More Notifications (
                  {notifications?.total_count - notifications?.messages?.length})
                </Link>
              </>
            )}
          </Grid>
        </Drawer>
      )}
    </div>
  );
}
