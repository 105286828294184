import { createSlice } from '@reduxjs/toolkit';
import { LoginState } from '../login/login.type';
import { LoginApi } from 'api';
import { VerificationTypes } from 'redux/common/common.type';

const initialState: LoginState = {
  isLoading: false,
  isAuthenticated: false,
  userData: undefined,
  userToken: undefined,
  loginData: undefined,
  registrationStepsData: undefined,
  verificationMethod: VerificationTypes.SMS,
  loginError: undefined,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLoginError: (state, { payload }) => {
      state.loginError = payload;
    },
    setLogged: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setVerificationMethod: (state, { payload }) => {
      state.verificationMethod = payload;
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginApi.signIn.pending, (state) => {
        state.loginData = undefined;
        state.isLoading = true;
        state.loginError = undefined;
      })
      .addCase(LoginApi.signIn.fulfilled, (state, action) => {
        state.loginData = action.payload;
        state.userToken = document.cookie; // TODO tmp solution
        state.isLoading = false;
      })
      .addCase(LoginApi.signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = action.payload;
      })
      .addCase(LoginApi.verifyOTP.pending, (state) => {
        state.verifyOTPResponse = undefined;
        state.isLoading = true;
        state.loginError = undefined;
      })
      .addCase(LoginApi.verifyOTP.fulfilled, (state, action) => {
        state.verifyOTPResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.verifyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = action.payload;
      })
      .addCase(LoginApi.verifyUserEmailInvitationToken.pending, (state) => {
        state.userInvitationDetail = undefined;
        state.isLoading = true;
        state.loginError = undefined;
      })
      .addCase(LoginApi.verifyUserEmailInvitationToken.fulfilled, (state, action) => {
        state.userInvitationDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.verifyUserEmailInvitationToken.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = { ...action.payload, isLogOut: true };
      })
      .addCase(LoginApi.keyInvitationValidation.pending, (state) => {
        state.keyInvitationVerify = undefined;
        state.loginError = undefined;
      })
      .addCase(LoginApi.keyInvitationValidation.fulfilled, (state, action) => {
        state.keyInvitationVerify = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.keyInvitationValidation.rejected, (state, action) => {
        state.loginError = { ...action.payload, isLogOut: true };
        state.isLoading = false;
      })
      .addCase(LoginApi.getUserDetails.pending, (state) => {
        state.isLoading = true;
        state.userData = undefined;
      })
      .addCase(LoginApi.getUserDetails.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.getUserDetails.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(LoginApi.getRegistrationSteps.pending, (state) => {
        state.registrationStepsData = undefined;
      })
      .addCase(LoginApi.getRegistrationSteps.fulfilled, (state, action) => {
        state.registrationStepsData = action.payload;
      })
      .addCase(LoginApi.getRegistrationSteps.rejected, (state, action) => {
        state.loginError = action.payload;
      })

      .addCase(LoginApi.getTenantDetail.pending, (state) => {
        state.tenantDetailData = undefined;
      })
      .addCase(LoginApi.getTenantDetail.fulfilled, (state, action) => {
        state.tenantDetailData = action.payload;
      })
      .addCase(LoginApi.getTenantDetail.rejected, (state, action) => {
        state.loginError = action.payload;
      })
      .addCase(LoginApi.getCustomerConfig.pending, (state) => {
        state.customerConfigData = undefined;
      })
      .addCase(LoginApi.getCustomerConfig.fulfilled, (state, action) => {
        state.customerConfigData = action.payload;
      })
      .addCase(LoginApi.getCustomerConfig.rejected, (state, action) => {
        state.loginError = action.payload;
      })
      .addCase(LoginApi.updateUserLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginApi.updateUserLevel.fulfilled, (state, action) => {
        state.loginData = action.payload; //TODO check the response
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(LoginApi.updateUserLevel.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.createBasicSignup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginApi.createBasicSignup.fulfilled, (state, action) => {
        state.loginData = action.payload; //TODO check the response
        state.isLoading = false;
        state.userToken = '6868y8'; // TODO map the token
      })
      .addCase(LoginApi.createBasicSignup.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.logout.pending, (state) => {
        return { ...state, ...initialState };
      })
      .addCase(LoginApi.logout.fulfilled, (state) => {
        return { ...state, ...initialState };
      })
      .addCase(LoginApi.logout.rejected, (state, action) => {
        return { ...state, ...initialState, loginError: action.payload };
      });
  },
});

export const { setIsLoading, reset, setUserData, setLogged, setLoginError, setVerificationMethod } =
  loginSlice.actions;

export default loginSlice.reducer;
