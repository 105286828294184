import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { DASHBOARD_PAGES } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function LoginCheck({ children }: GuestGuardProps) {
  const { isAuthenticated, role } = useAuth();

  if (isAuthenticated) {
    return (
      <Navigate
        to={
          role === 'funder' ? DASHBOARD_PAGES.funderDashboard : DASHBOARD_PAGES.marketplace // TODO check here
        }
      />
    );
  }

  return <>{children}</>;
}
