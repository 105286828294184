import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonSuccessResponse, ServerErrors } from 'redux/common/common.type';
import {
  CompanyInvitationData,
  CompanyTypeList,
  DocumentTypesData,
  FinancialProfileData,
  IndustryData,
  InvoiceTradingData,
  McclistData,
  NaicsList,
  OrganizationListData,
  SicList,
  UserInvitation,
  TransactionHistoryData,
  SendInvitationPayload,
  SettingsState,
  LibraryDocumentsData,
} from 'redux/settings/settings.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getCompanyTypeList = createAsyncThunk<CompanyTypeList[], void, { rejectValue: ServerErrors }>(
  'getCompanyTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<CompanyTypeList[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/company-type-list/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getNaicsList = createAsyncThunk<NaicsList[], void, { rejectValue: ServerErrors }>(
  'getNaicsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<NaicsList[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/naics-list/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getInvoiceTradingList = createAsyncThunk<
  InvoiceTradingData,
  void,
  { rejectValue: ServerErrors }
>('getInvoiceTradingList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceTradingData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/invoice-trading-list/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSICCodeList = createAsyncThunk<SicList[], void, { rejectValue: ServerErrors }>(
  'getSICCodeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<SicList[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/sic-list/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getMCCList = createAsyncThunk<McclistData[], void, { rejectValue: ServerErrors }>(
  'getMCCList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<McclistData[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/mcc-list/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getIndustry = createAsyncThunk<IndustryData[], void, { rejectValue: ServerErrors }>(
  'getIndustry',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<IndustryData[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/industry/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const pHeartbeat = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'pHeartbeat',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(`${process.env.REACT_APP_PLAID}link/heartbeat`, {
        ...payload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const pStart = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'pStart',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(`${process.env.REACT_APP_PLAID}link/workflow/start`, {
        ...payload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const pEvent = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'pEvent',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(`${process.env.REACT_APP_PLAID}link/workflow/event`, {
        ...payload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getOrganizationList = createAsyncThunk<
  OrganizationListData,
  void,
  { rejectValue: ServerErrors }
>('getOrganizationList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<OrganizationListData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/organization_list/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getUserInvitation = createAsyncThunk<UserInvitation[], string, { rejectValue: ServerErrors }>(
  'getUserInvitation',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<UserInvitation[]>(
        `${process.env.REACT_APP_SERVER_URL}multi_user_access/user_invitation/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const sendUserInvitation = createAsyncThunk<
  CommonSuccessResponse,
  Partial<SendInvitationPayload>,
  { rejectValue: ServerErrors }
>('sendUserInvitation', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}multi_user_access/user_invitation/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const deleteUserInvitation = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteUserInvitation',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<UserInvitation[]>(
        `${process.env.c}multi_user_access/user_invitation/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getCompanyInvitation = createAsyncThunk<
  CompanyInvitationData,
  string,
  { rejectValue: ServerErrors }
>('getCompanyInvitation', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<CompanyInvitationData>(
      `${process.env.REACT_APP_SERVER_URL}multi_user_access/company_invitation/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO
const getApproverTypeList = createAsyncThunk<any[], void, { rejectValue: ServerErrors }>(
  'getApproverTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/approver_type_list/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const getDocumentRequests = createAsyncThunk<any[], void, { rejectValue: ServerErrors }>(
  'getDocumentRequests',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/document_requests/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getAllLibraryDocuments = createAsyncThunk<
  LibraryDocumentsData[],
  void,
  { rejectValue: ServerErrors }
>('getAllDocumentsAndStatus', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<LibraryDocumentsData[]>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/company_document/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const getDocumentTypes = createAsyncThunk<DocumentTypesData[], void, { rejectValue: ServerErrors }>(
  'getDocumentTypes',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<DocumentTypesData[]>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/document_types/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getFinancialProfile = createAsyncThunk<
  FinancialProfileData,
  void,
  { rejectValue: ServerErrors }
>('getFinancialProfile', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<FinancialProfileData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/financial_profile/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO Update financial profile
const updateFinancialProfile = createAsyncThunk<
  FinancialProfileData,
  any,
  { rejectValue: ServerErrors }
>('updateFinancialProfile', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<FinancialProfileData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/financial_profile/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO
const changeUserAccess = createAsyncThunk<
  CommonSuccessResponse,
  { email: string; access_level: string },
  { rejectValue: ServerErrors }
>('changeUserAccess', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}multi_user_access/update_user_access_level/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO Update financial profile
const deleteOwner = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteOwner',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/company/owner/delete/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const calculateEDITDA = createAsyncThunk<
  { ebitda: number },
  Partial<FinancialProfileData>,
  { rejectValue: ServerErrors }
>('calculateEDITDA', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<{ ebitda: number }>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/calculate_ebitda/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO
const getTransactionHistory = createAsyncThunk<
  TransactionHistoryData,
  string,
  { rejectValue: ServerErrors }
>('getTransactionHistory', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<TransactionHistoryData>(
      `${process.env.REACT_APP_SERVER_URL}payments/transaction_history/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getCompanyTypeList,
  getNaicsList,
  getInvoiceTradingList,
  getSICCodeList,
  getMCCList,
  getIndustry,
  pHeartbeat,
  pStart,
  pEvent,
  getOrganizationList,
  getUserInvitation,
  getCompanyInvitation,
  getApproverTypeList,
  getDocumentRequests,
  getAllLibraryDocuments,
  getDocumentTypes,
  getFinancialProfile,
  updateFinancialProfile,
  getTransactionHistory,
  calculateEDITDA,
  sendUserInvitation,
  deleteUserInvitation,
  deleteOwner,
  changeUserAccess,
};
