import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  Stack,
  useTheme,
} from '@mui/material';

// components
import Iconify from 'components/Iconify';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'redux/store';
import { CommonApi } from 'api';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGES } from 'routes/paths';
import ConfirmationModal from 'components/Models/ConfirmationModal';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 54;
const APPBAR_DESKTOP = 54;

const SearchbarStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface ErrorProps {
  message_header?: string;
  message?: string;
  open: boolean;
}

export default function Searchbar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState<ErrorProps>({ open: false });
  const [value, setValue] = useState<string>('');
  const { role } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    const action = await dispatch(CommonApi.getGlobalSearchValue(`?q=${value}`));
    if (CommonApi.getGlobalSearchValue.fulfilled.match(action)) {
      // TODO close and navigate or setSTate, collect all screens and page names
      const searchScreen = action.payload?.screen;
      //   let searchScreen;
      // // if (this.roleName === 'funder') {
      // // this.router.navigateByUrl('/buy-invoice', { state: { invoice:
      // {invoice_number:this.searchText} } });
      // // } else {
      // // this.router.navigateByUrl('/sell-invoice', { state: { invoice:
      // {invoice_number:this.searchText} } });
      // // }
      // this.dshboardServices.getGlobalSearchValue(this.searchText).subscribe(
      // (response: any) => {
      // console.log('Global search response: ', response);
      // searchScreen = response.screen;
      // if (this.roleName === 'funder') {
      // if (searchScreen === 'history') {
      // this.router.navigateByUrl('/history', { state: { invoice: { invoiceSearch: this.searchText } } });
      // } else if (searchScreen === 'Dashboard') {
      // this.router.navigateByUrl('/dashboard', { state: { invoice: { invoice_id: this.searchText } } });
      // } else if (searchScreen === 'repayment') {
      // this.router.navigateByUrl('/v2/repayment/list', { state: { invoiceSearch: this.searchText } });
      // } else if (searchScreen === 'active') {
      // this.router.navigateByUrl('/active', { state: { invoiceSearch: this.searchText } });
      // } else if (searchScreen === 'approvals') {
      // this.router.navigateByUrl('/approvals', { state: { invoiceSearch: this.searchText } });
      // } else if (searchScreen === 'contacts_seller') {
      // this.router.navigateByUrl('/v2/contact', { state: { invoiceSearch: this.searchText } });
      // this.contactService.globalSearchContactBehaviourObj.next({ invoiceSearch: this.searchText, tab:
      // "seller" })
      // } else if (searchScreen === 'contacts_buyer') {
      // this.router.navigateByUrl('/v2/contact', { state: { invoiceSearch: this.searchText } });
      // this.contactService.globalSearchContactBehaviourObj.next({ invoiceSearch: this.searchText, tab:
      // "buyer" })
      // }
      // } else if (this.roleName === 'seller' || this.roleName === 'buyer') {
      // if (searchScreen === 'history') {
      // this.router.navigateByUrl('/history', { state: { invoice: { invoiceSearch: this.searchText } } });
      // } else if (searchScreen === 'Dashboard' || searchScreen === 'sell_invoices') {
      // this.router.navigateByUrl('/dashboard', { state: { invoiceSearch: this.searchText } });
      // } else if (searchScreen === 'repayment') {
      // this.router.navigateByUrl('/v2/repayment/list', { state: { invoiceSearch: this.searchText } });
      // } else if (searchScreen === 'contacts') {
      // this.router.navigateByUrl('/contact', { state: { invoiceSearch: this.searchText } });
      // this.contactService.globalSearchContactBehaviourObj.next(this.searchText)
      // } else if (searchScreen === 'sell_invoices_in_offer') {
      // this.router.navigateByUrl('/dashboard', { state: { invoiceSearch: this.searchText, tab: "eligible" } });
      // } else if (searchScreen === 'sell_invoices_queued') {
      // this.router.navigateByUrl('/dashboard', { state: { invoiceSearch: this.searchText, tab: "queue" } });
      // } else if (searchScreen === 'sell_invoices_need_verification') {
      // this.router.navigateByUrl('/dashboard', { state: { invoiceSearch: this.searchText, tab:
      // "need_verification" } });

      if (searchScreen === 'history') {
        navigate(DASHBOARD_PAGES.history, {
          state: { invoice: { invoiceSearch: value } },
        });
      } else if (searchScreen === 'repayment') {
        navigate(DASHBOARD_PAGES.repayment, {
          state: { invoiceSearch: value },
        });
      } else if (role === 'funder') {
        if (searchScreen === 'Dashboard') {
          navigate(DASHBOARD_PAGES.funderDashboard, {
            state: { invoice: { invoice_id: value } },
          });
        } else if (searchScreen === 'active') {
          navigate(DASHBOARD_PAGES.active, { state: { invoiceSearch: value } });
        }
        // TODO where is approval modal
        // else if (searchScreen === 'approvals') {
        //   this.router.navigateByUrl('/approvals', { state: { invoiceSearch: this.searchText } });
        // }
      } else if (role === 'seller') {
        if (searchScreen === 'Dashboard') {
          navigate(DASHBOARD_PAGES.dashboard, {
            state: { invoiceSearch: value },
          });
        }
      }
    }
    if (CommonApi.getGlobalSearchValue.rejected.match(action)) {
      if (action.payload?.error) {
        setOpenMessage({ ...action.payload, open: true });
      }
      handleClose();
      // TODO navigate or setSTate, collect all screens and page names
    }
  };

  return (
    <>
      <ConfirmationModal
        open={openMessage.open}
        onClose={() => {
          setOpenMessage({ message: undefined, message_header: undefined, open: false });
        }}
        message={openMessage.message}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Stack direction="row-reverse" alignItems="center">
          <IconButton
            onClick={handleOpen}
            color="inherit"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Iconify
              icon={'eva:search-fill'}
              width={26}
              height={26}
              sx={{
                color: 'text.active',
                cursor: 'pointer',
              }}
            />
          </IconButton>

          <Slide
            direction="left"
            in={open}
            timeout={{
              exit: 0.2,
            }}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                value={value}
                placeholder="What you are looking for"
                sx={{
                  mr: 0.5,
                  fontWeight: 'fontWeightBold',
                  boxShadow: (theme) => theme.shadows[2],
                  borderRadius: '20px',
                  padding: '5px 15px',
                  transition: '0.5s ease all',
                  fontSize: '12px',
                }}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </div>
          </Slide>
        </Stack>
      </ClickAwayListener>
    </>
  );
}
