import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { dashboardLabels, MenuItemData, SettingsSubMenu } from 'routes/paths';
import DashboardHeader from '../header/DashboardHeader';
import { getIconSmall } from '../../routes/paths';
import useAuth from 'hooks/useAuth';
import { Fragment, useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Collapse from '@mui/material/Collapse';
import find from 'lodash/find';
import { useDispatch } from 'redux/store';
import { drawerWidth } from 'commonTypes/settings';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    PointerEvent: 'auto',
    background: theme.palette.primary.main,
    '.Sidebar_Btn_open': {
      opacity: 0,
      PointerEvent: 'none',
    },
  },
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(12)} + 4px)`,
    '.sidebar-header': {
      padding: '0 0 0 30px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: '-100%',
    right: 0,
    bottom: 0,
    zIndex: 9999,
    background: theme.palette.primary.main,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '0px',
  '&.sidebar-header': {
    minHeight: '80px',
  },
  position: 'relative',
  justifyContent: 'space-between',
  padding: '0 0 0 30px',
  '.Sidebar_Btn_open': {
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    position: 'absolute',
    right: -12,
    zIndex: 10,
    background: theme.palette.secondary.main,
    padding: 0,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    '.Sidebar_Btn_open': {
      right: -51,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      borderTopRightRadius: '21px',
      borderBottomRightRadius: '21px',
      width: '2.1em',
      height: '1.8em',
    },
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.customColors?.highLightColors?.menuHighlight,
  '.MuiListItem-root.MuiListItem-dense': {
    '&.Mui-selected': {
      backgroundColor: theme.palette.customColors?.highLightColors?.submenuActive
    },
    '&:hover': {
      backgroundColor: theme.palette.customColors?.highLightColors?.submenuActive,
    },
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  color: 'white',
  padding: 0,
  '&& .Mui-selected, && .Mui-selected:hover': {
    '&, & .MuiListItemIcon-root': {
      color: 'white',
      // color: theme.palette.grey[500],
    },
  },
  '.MuiListItem-root': {
    display: 'block',
    padding: '0 40px 0 30px',
    '&:hover,&.Mui-selected': {
      backgroundColor: theme.palette.customColors?.highLightColors?.menuHighlight,
    },
    '.MuiButtonBase-root': {
      minHeight: 48,
      justifyContent: 'flex-start',
      display: 'flex',
      transition: 'none',
      padding: 0,
      '&:hover': {
        background: 'inherit',
      },
      '.MuiListItemText-root': {
        margin: '0 0 0 10px',
      },
      '.MuiListItemIcon-root': {
        minWidth: 0,
        justifyContent: 'center',
        marginRight: 0,
        svg: {
          path: {
            fill: 'white',
          },
        },
      },
    },
  },
  '.MuiTouchRipple-root': {
    display: 'none'
  },
  '&.MuiList-root': {
    '@media screen and (max-height: 800px)': {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

export default function Sidebar() {
  const isMobile = useMediaQuery('(max-width:600px)', { noSsr: true });
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | undefined>();
  const { userData } = useAuth();

  const handleDrawer = () => {
    setOpen(!open);
  };
  //TODO wrong
  const handleMobileClickClose = () => {
    if (isMobile) {
      setOpen(false);
    }
  };
  const handleMobileClickOpen = () => {
    setOpen(true);
  };
  const getCurrentSubmenuPath = (v: MenuItemData): string => {
    if (!location.pathname.includes(v.path)) {
      return v.subMenu[0].path;
    }
    const c = find(v.subMenu, (f) => f.path === location.pathname);
    if (!!c) {
      return c.path;
    }
    return v.subMenu[0].path;
  };
  const getWidth = () => (isMobile ? 0 : 96);
  const sidebarIcon = getIconSmall[process.env.REACT_APP_TENANT_NAME as keyof typeof getIconSmall];
  const sidebarItems = () => (
    <StyledList>
      {dashboardLabels
        .filter(
          (f) =>
            f.tenant.includes(process.env.REACT_APP_TENANT_NAME as string) &&
            f.roles?.some((a) => userData?.user_details.role.includes(a))
        )
        .map((v, index) => (
          <Fragment key={index}>
            <ListItem
              key={index}
              dense
              selected={
                v.path === location.pathname || (v.isSubMenu && location.pathname.includes(v.path))
              }
              disablePadding
              onClick={() => {
                if (v.isSubMenu) {
                  if (!open) {
                    setOpen(true);
                  }
                  setOpenSubMenu(v.label === openSubMenu ? undefined : v.label);
                } else if (openSubMenu) {
                  setOpenSubMenu(undefined);
                } else {
                  if (isMobile) {
                    handleMobileClickClose();
                  }
                }
              }}>
              <ListItemButton
                component={RouterLink}
                to={v.isSubMenu ? getCurrentSubmenuPath(v) : v.path}
                sx={{
                  minHeight: 48,
                  justifyContent: 'flex-start',
                  display: 'flex',
                  transition: 'none',
                  padding: 0,
                  '&:hover': {
                    background: 'inherit',
                  },
                  '.MuiListItemText-root': {
                    margin: '0 0 0 10px',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 0 : 'auto',
                    justifyContent: 'center',
                    marginRight: 0,
                    svg: {
                      path: {
                        fill: 'white',
                      },
                    },
                  }}
                >
                  {v.icon}
                </ListItemIcon>
                <ListItemText
                  primary={v.label}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginBottom: v.marginBottom,
                    marginTop: v.marginTop,
                  }}
                />
                {v.isSubMenu && (v.label === openSubMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItemButton>
            </ListItem>
            {v.isSubMenu && (
              <StyledCollapse in={openSubMenu === v.label} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  onClick={handleMobileClickClose}>
                  {v.subMenu
                    .filter(
                      (f: SettingsSubMenu) =>
                        f.tenant.includes(process.env.REACT_APP_TENANT_NAME as string) &&
                        f.roles?.some((a) => userData?.user_details.role.includes(a))
                    )
                    .map((sub) => (
                      <ListItem
                        key={sub.label}
                        dense
                        selected={sub.path === location.pathname}
                        disablePadding>
                        <ListItemButton
                          component={RouterLink}
                          to={sub.path}>
                          <ListItemIcon>
                            {sub.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={sub.label}
                            primaryTypographyProps={{ fontSize: '12px' }}
                            sx={{
                              opacity: open ? 1 : 0,
                              marginBottom: v.marginBottom,
                              marginTop: v.marginTop,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </StyledCollapse>
            )}
          </Fragment>
        ))}
    </StyledList>
  );

  return (
    <>
      <AppBar
        position="absolute"
        color="inherit"
        open={open}
        elevation={4}
        sx={{
          width: `calc(100% - ${open ? drawerWidth : getWidth()}px)`,
          ml: `${open ? drawerWidth : getWidth()}px`,
          height: 'auto',
          boxShadow: 'none',
          background: (theme) => theme.palette.customColors?.appBackground,
          '@media screen and (max-width: 600px)': {
            left: 0,
            margin: 0,
          },
        }}
      >
        <Box
          sx={{
            paddingTop: '15px',
            '@media screen and (max-width: 600px)': {
              padding: 0,
            },
          }}
        >
          <DashboardHeader />
        </Box>
      </AppBar>
      <Drawer
        open={open}
        variant="permanent"
        sx={{
          '.MuiDrawer-paper': {
            background: (theme) => theme.palette.primary.main,
            overflow: 'visible',
            boxShadow: (theme) => theme.shadows[0],
            '@media screen and (max-width: 600px)': {
              position: 'inherit',
              width: '100%',
              left: 'inherit',
              boxShadow: 'none',
            },
          },
          '.MuiDivider-root': {
            display: 'none',
          },
        }}
      >
        <DrawerHeader className="sidebar-header">
          <Box sx={{ overflow: 'hidden' }}>
            <img src={open ? sidebarIcon?.logo : sidebarIcon?.small} />
          </Box>
          <IconButton
            onClick={isMobile ? handleMobileClickOpen : handleDrawer}
            className="Sidebar_Btn_open"
          >
            {isMobile ? (
              <img src={sidebarIcon?.small} />
            ) : open ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {sidebarItems()}
      </Drawer>

      {/* {!isMobile && location.pathname.includes('/dashboard/settings') && (
        <SettingsDrawer open={open} />
      )} */}
    </>
  );
}
