import { ReactNode, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  Palette,
} from '@mui/material/styles';
import useSettings from 'hooks/useSettings';
import angelsdenTheme from '../config/angelsden.json';
import cityTheme from '../config/city.json';
import codTheme from '../config/cod.json';
import crowdzTheme from '../config/crowdz.json';
import egTheme from '../config/eg.json';
import fbTheme from 'config/fb.json';
import darkMode from 'config/darkmode.json';
import { useSelector } from 'redux/store';
import componentsOverride from './override';

//

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// TODO complete the custom theme, remove mock
export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';
  const { ...state } = useSelector((state) => state.config);
  // TODO test code start
  const darkModeTest = false; //TODO test the dark mode here
  let defaultTheme: any = undefined;
  if (darkModeTest) {
    defaultTheme = darkMode;
  } else if (process.env.REACT_APP_TENANT_NAME === 'City') {
    defaultTheme = cityTheme;
  } else if (process.env.REACT_APP_TENANT_NAME === 'EG') {
    defaultTheme = egTheme;
  } else if (process.env.REACT_APP_TENANT_NAME === 'COD') {
    defaultTheme = codTheme;
  } else if (process.env.REACT_APP_TENANT_NAME === 'Angelsden') {
    defaultTheme = angelsdenTheme;
  } else if (process.env.REACT_APP_TENANT_NAME === 'FB') {
    defaultTheme = fbTheme;
  } else {
    defaultTheme = crowdzTheme;
  }

  const currentPalette = state.configData?.palette
    ? { palette: state.configData.palette }
    : { palette: defaultTheme.palette as Palette };
  // TODO test code ends
  // TODO add dark
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: currentPalette.palette,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
