import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
// routes
import { PATH_AUTH } from 'routes/paths';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { LoginAndVerifyPayload } from 'redux/login/login.type';
import Typography from '@mui/material/Typography';
import FormikTextField from 'components/formik/FormikTextField';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

interface OwnProps {
  onSubmit: (values: LoginAndVerifyPayload) => void;
  title?: string;
}
const initialValues: LoginAndVerifyPayload = {
  email: '',
  password: '',
};

const StyledLink = styled(Link)(({ theme }) => ({
  paddingTop: 18,
  fontSize: 15,
  letterSpacing: 0.7,
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('password is required'),
});

const LoginForm = ({ onSubmit, title }: OwnProps) => {
  const [showPassword, setShowPassword] = useState<boolean>();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} autoComplete="off">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: 4 }}>
                {title}
              </Typography>
              {/* <Typography variant="body2">{currentUserProps?.description}</Typography> */}
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`email`}
                autoComplete={'off'}
                label={
                  <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                    Company Email Address
                  </span>
                }
                marginBottom={'10px'}
                component={FormikTextField}
              />
              <ErrorMessage name="email" component="div" className="invalid-feedback" />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`password`}
                type={showPassword ? 'text' : 'password'}
                autoComplete={'off'}
                component={FormikTextField}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Password</span>}
                marginBottom={'10px'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage name="password" component="div" />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Login
              </Button>
              <Stack direction={'row'} flexDirection={'column'} justifyContent={'space-around'}>
                <StyledLink href={PATH_AUTH.forgot}>Forgot Your Password</StyledLink>
                <StyledLink variant="subtitle1" href={PATH_AUTH.register}>
                  Don't Have an Account? Sign Up
                </StyledLink>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
