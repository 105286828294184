import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonSuccessResponse, ServerErrors } from 'redux/common/common.type';
import {
  LoginAndVerifyPayload,
  UserData,
  RegistrationSteps,
  TenantDetail,
  LoginResponse,
  CustomerConfigDetailsData,
  BasicSignupPayload,
  UserInvitationVerificationTokenData,
  KeyInvitationValidationPayload,
  InvitedUserBasicSignUpPayload,
} from 'redux/login/login.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const signIn = createAsyncThunk<
  LoginResponse,
  LoginAndVerifyPayload,
  { rejectValue: ServerErrors }
>('login', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<LoginResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/login/`,
      {
        ...payload,
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review it, check the payload, response
const verifyOTP = createAsyncThunk<any, LoginAndVerifyPayload, { rejectValue: ServerErrors }>(
  'verifyOTP',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/verify-otp/`,
        {
          ...payload,
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getUserDetails = createAsyncThunk<UserData, void, { rejectValue: ServerErrors }>(
  'getUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<UserData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/user_details/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO mock delete
const getUserDetailsSeller = createAsyncThunk<UserData, void, { rejectValue: ServerErrors }>(
  'getUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<UserData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/user_details/seller`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getTenantDetail = createAsyncThunk<TenantDetail, void, { rejectValue: ServerErrors }>(
  'getTenantDetail',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<TenantDetail>(
        `${process.env.REACT_APP_SERVER_URL}appui/tenant_details/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const logout = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'logout',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/logout/`,
        {}
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getRegistrationSteps = createAsyncThunk<
  RegistrationSteps[],
  void,
  { rejectValue: ServerErrors }
>('getRegistrationSteps', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<RegistrationSteps[]>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/registration_steps/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const getCustomerConfig = createAsyncThunk<
  CustomerConfigDetailsData,
  void,
  { rejectValue: ServerErrors }
>('getCustomerConfig', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<CustomerConfigDetailsData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/customer_config_details/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const updateCustomerConfig = createAsyncThunk<
  CustomerConfigDetailsData,
  Partial<CustomerConfigDetailsData>,
  { rejectValue: ServerErrors }
>('getCustomerConfig', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CustomerConfigDetailsData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/customer_config_details/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check the payload
const updateUserLevel = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'updateUserLevel',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/user_level/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const createBasicSignup = createAsyncThunk<any, BasicSignupPayload, { rejectValue: ServerErrors }>(
  'createBasicSignup',
  async (payload, { rejectWithValue }) => {
    console.log('>>>', document.cookie);
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/basic_signup/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review
const verifyUserEmailInvitationToken = createAsyncThunk<
  UserInvitationVerificationTokenData,
  string,
  { rejectValue: ServerErrors }
>('verifyUserEmailInvitationToken', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<UserInvitationVerificationTokenData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/verify_email/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review,
const editUserRole = createAsyncThunk<
  CommonSuccessResponse,
  { role_type: number },
  { rejectValue: ServerErrors }
>('editUserRole', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/user_role/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review,
const advancedSignUp = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'advancedSignUp',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/advanced_signup/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review, consider to include mbe
const completeSignUp = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'completeSignUp',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/full_signup/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review
// INVITE USER KEY VERIFICATION
const keyInvitationValidation = createAsyncThunk<
  any,
  KeyInvitationValidationPayload,
  { rejectValue: ServerErrors }
>('keyInvitationValidation', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}multi_user_access/verify_invitation_key/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review
const invitedUserBasicSignUp = createAsyncThunk<
  any,
  InvitedUserBasicSignUpPayload,
  { rejectValue: ServerErrors }
>('invitedUserBasicSignUp', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}multi_user_access/invited_user_basic_signup/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  signIn,
  getUserDetails,
  logout,
  getTenantDetail,
  getRegistrationSteps,
  getUserDetailsSeller,
  verifyOTP,
  getCustomerConfig,
  updateCustomerConfig,
  updateUserLevel,
  createBasicSignup,
  verifyUserEmailInvitationToken,
  editUserRole,
  advancedSignUp,
  completeSignUp,
  invitedUserBasicSignUp,
  keyInvitationValidation,
};
