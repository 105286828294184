import { ArrowUpward } from '@mui/icons-material';
import { Button, Grid, Stack, styled, Typography } from '@mui/material';
import { CommonApi, SettingsApi } from 'api';
import SettingsBreadCrumbs from 'components/breadCrumbs/SettingsBreadCrumbs';
import CustomSnackbar from 'components/CustomSnackbar';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import { useEffect, useState } from 'react';
import { CommonErrorModalProps } from 'redux/common/common.type';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/store';
import DocumentFileUploadBtn from './DocumentFileUploadBtn';
import Page from 'components/Page';

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  marginBottom: 1,
  color: theme.palette.grey[800],
}));

const DocumentLibraryPage = () => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>();
  const breadCrumbs = { name: 'Settings/Document Library', path: '#' };
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const dispatch = useDispatch();
  const { libraryDocs } = useSelector((state) => state.settings);

  const handleUploadFile = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'documents',
      JSON.stringify([{ doc_type: 'cashflow_statement', request_id: null }])
    ); // TODO not needed
    bodyFormData.append('file_obj', file);

    const action = await dispatch(CommonApi.uploadDocument(bodyFormData));
    if (CommonApi.uploadDocument.fulfilled.match(action)) {
      setOpenConfirmationModal(true);
      action.payload?.success &&
        setOpenSnackbar({
          open: true,
          message: action.payload?.success?.message,
          type: 'success',
        });
    }
    if (CommonApi.uploadDocument.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  const notificationParams = useParams();
  useEffect(() => {
    if (!!notificationParams) {
      //TODO dispatch API with correct params
      // /api/invoice-exchange/dashboard/settings/document-library?defaultState=${notificationParams.defaultState}
    }
  }, [notificationParams]);

  useEffect(() => {
    dispatch(SettingsApi.getAllLibraryDocuments());
  }, []);
  return (
    <Page title="Document upload">
      <SettingsBreadCrumbs data={breadCrumbs} />
      <Grid container columnSpacing={4} rowGap={4}>
        {Array.isArray(libraryDocs) &&
          libraryDocs.map((p) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={p.label}>
              <Stack>
                <StyledTypo variant="body2">Overall Aging Report</StyledTypo>
                <DocumentFileUploadBtn handleFileUpload={handleUploadFile} fileName={p.doc_name} />
              </Stack>
            </Grid>
          ))}
      </Grid>
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
    </Page>
  );
};
export default DocumentLibraryPage;
