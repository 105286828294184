import { useEffect, useState } from 'react';
import { InputProps } from '@mui/material/Input';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const FormControlWidth = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
  },
}));
const LabelAction = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 0.21,
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  [theme.breakpoints.up('xl')]: {
    fontSize: 13,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 16,
  },
}));

interface OwnProps {
  required: boolean;
  textFieldProps: InputProps;
  type?: string;
  value: string | string[];
}
interface OwnCustomProps {
  formHelperTextProps: FormHelperTextProps;
  formControlProps: FormControlProps;
  label?: string;
  fontSize?: string;
  marginBottom?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
  labelAction?: string;
  labelWidth?: string;
  onLabelActionClick?: () => void;
  currency?: string;
}

type Props = OwnProps & OwnCustomProps;

interface LabelProps {
  width?: string;
}

const FormikTextField = ({
  variant = 'standard',
  ...props
}: Partial<Props> & FieldProps & TextFieldProps) => {
  const {
    required,
    formControlProps,
    formHelperTextProps,
    field,
    label,
    fontSize,
    marginBottom,
    helperText,
    labelAction,
    textFieldProps,
    onLabelActionClick,
    labelWidth,
    currency,
    ...others
  } = props;

  const [formattedValue, setFormattedValue] = useState();
  const { name, value = '' } = props.field;
  const { setFieldValue } = props.form;

  useEffect(() => {
    if (currency && !!value && new Intl.NumberFormat()) {
      // @ts-ignore
      setFormattedValue(new Intl.NumberFormat({ currency }).format(value));
    } else {
      setFormattedValue(value);
    }
  }, []);

  return (
    <FormControlWidth
      fullWidth
      {...formControlProps}
      error={false}
      disabled={props.disabled}
      required={required}
    >
      {/* <Wrapper width={labelWidth}>
        <LabelStyle variant="body2" color="textSecondary">
          {label}
        </LabelStyle>
        {labelAction && <LabelAction onClick={onLabelActionClick}>{labelAction}</LabelAction>}
      </Wrapper> */}

      <TextField
        fullWidth
        {...others}
        {...field}
        required={required}
        label={label}
        helperText={helperText}
        sx={{
          fontSize: { fontSize },
          marginBottom: { marginBottom },
          '.MuiInputBase-root': {
            height: '37px',
          },
          '.MuiInputLabel-root': {
            fontSize: '14px ',
          },
          '.MuiInputBase-input': {
            fontSize: { fontSize },
          },
        }}
        name={name}
        value={!!currency ? formattedValue : value}
        onFocus={() => {
          if (currency) setFormattedValue(value);
        }}
        onBlur={() => {
          if (currency && !!value && new Intl.NumberFormat()) {
            // @ts-ignore
            setFormattedValue(new Intl.NumberFormat({ currency }).format(value));
          }
        }}
        InputProps={textFieldProps}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        variant={variant}
      />
      {/* <StyledTextField variant="outlined" {...others} {...field} required={required} /> */}
      <FormHelperText {...formHelperTextProps} error={false} disabled={false} required={required} />
      {/* <Typography color='error'>{props.form.touched[field.name] && props.form.errors[field.name]}</Typography> */}
    </FormControlWidth>
  );
};

export default FormikTextField;
