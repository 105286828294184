import { createSlice } from '@reduxjs/toolkit';
import { ContactsState } from '../contacts/contacts.type';
import { ContactsApi } from 'api';

const initialState: ContactsState = {
  isLoading: false,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false; //TODO
    },
    setContactsError: (state, action) => {
      state.contactsError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ContactsApi.getDirectoryList.pending, (state) => {
        state.isLoading = true;
        state.directListData = undefined;
      })
      .addCase(ContactsApi.getDirectoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directListData = action.payload;
      })
      .addCase(ContactsApi.getDirectoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.contactsError = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityBuyer.pending, (state) => {
        state.isLoading = true;
        state.contactsVisibilityBuyerSellerData = undefined;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityBuyer.fulfilled, (state, action) => {
        state.isLoading = true;
        state.contactsVisibilityBuyerSellerData = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityBuyer.rejected, (state, action) => {
        state.isLoading = false;
        state.contactsError = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilitySeller.pending, (state) => {
        state.isLoading = true;
        state.contactsVisibilityBuyerSellerData = undefined;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilitySeller.fulfilled, (state, action) => {
        state.isLoading = true;
        state.contactsVisibilityBuyerSellerData = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilitySeller.rejected, (state, action) => {
        state.isLoading = false;
        state.contactsError = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityFilter.pending, (state) => {
        state.isLoading = true;
        state.filterData = undefined;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filterData = action.payload;
      })
      .addCase(ContactsApi.getInvoiceContactsVisibilityFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.contactsError = action.payload;
      })
      .addCase(ContactsApi.getCompanyDirectoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ContactsApi.getCompanyDirectoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companySearchData = action.payload;
      })
      .addCase(ContactsApi.getCompanyDirectoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.contactsError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setContactsError } = contactsSlice.actions;

export default contactsSlice.reducer;
