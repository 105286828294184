import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
// hooks
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
// components
import Page from 'components/Page';
import LoginForm from './LoginForm';
import { LoginAndVerifyPayload } from 'redux/login/login.type';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { authProps, LoginPropsData } from './loginConfig';
import { CirclesWithBar } from 'react-loader-spinner';
import { CommonErrorModalProps, VerificationTypes } from 'redux/common/common.type';
import { UserManagementApi } from 'api';
import { useDispatch } from 'redux/store';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import { setLogged, setLoginError, setVerificationMethod } from 'redux/slices/login';
import InputOTP from 'components/InputOTP';
import FacebookLoginSection from './FacebookLoginSection';
import { checkPreRegistrationSteps, getRegistrationStepsRoute } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import CustomSnackbar from 'components/CustomSnackbar';

interface ImageProps {
  image?: string;
}

const StyledDiv = styled(
  'div',
  {}
)<ImageProps>(({ image, theme }) => ({
  backgroundImage: `url(${image})`,
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  maxWidth: '575px',
  backgroundPosition: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'flex-end',
  position: 'relative',
}));

const StyledMainCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1000px',
    width: '100%',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

const StyledRightbox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'left',
  padding: '90px',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: '60px',
  },
}));
const StyledLinks = styled(Button)(({ theme }) => ({
  fontSize: 15,
  letterSpacing: 0.7,
  textDecoration: 'none',
}));

const LoginPage = () => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [formValues, setFormValues] = useState<LoginAndVerifyPayload | undefined>();
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const {
    signIn,
    isLoading,
    verificationMethod,
    loginData,
    loginError,
    verifyLoginOTP,
    logout,
    isAuthenticated,
    userData,
    registrationStepsData,
  } = useAuth();
  const [currentUserProps, setCurrentUserProps] = useState<LoginPropsData | undefined>(
    authProps.filter((f) => {
      return f.name === process.env.REACT_APP_TENANT_NAME;
    })[0]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!loginData && loginData.success && !!userData && !!registrationStepsData) {
      const regSteps = checkPreRegistrationSteps(registrationStepsData);
      if (!!regSteps) {
        const step =
          !!regSteps && regSteps.step_number <= Number(userData.user_details.registration_progress)
            ? regSteps.step_number
            : Number(userData.user_details.registration_progress) + 1;

        let currentIndex = step;
        if (currentIndex <= 2) {
          currentIndex = 0;
        } else if (currentIndex === 3) {
          currentIndex = 4;
        } else if (currentIndex === 4) {
          currentIndex = 5;
        } else if (currentIndex === 5 || currentIndex === 6) {
          currentIndex = 6;
        } else if (currentIndex === 7) {
          currentIndex = 7;
        }

        navigate(PATH_AUTH.register, { state: currentIndex });
      } else if (userData.user_details.freeze) {
        dispatch(
          setLoginError({
            isLogOut: true,
            error: {
              message: `Your account is currently frozen, please contact at ${currentUserProps?.contactEmail}`,
            },
          })
        );
      } else if (!isAuthenticated) {
        dispatch(setLogged(true));
      }
    }
  }, [loginData, registrationStepsData, userData]);

  useEffect(() => {
    if (!!formValues && !!loginData && !loginData.is_otp_required && loginData.status === 200) {
      setFormValues(undefined);
    } else if (
      !isAuthenticated &&
      !formValues &&
      !!loginData &&
      loginData.is_otp_required &&
      !!loginData.phone_number
    ) {
      logout();
    }
  }, [loginData]);

  useEffect(() => {
    if (!!loginError) {
      setOpenErrorDialog(true);
    }
  }, [loginError]);

  const handleOnSubmit = (values: LoginAndVerifyPayload) => {
    signIn(values);
    setFormValues(values);
  };

  const handleRequestOTP = async (type?: VerificationTypes, resend?: string) => {
    if (verificationMethod !== type || resend === 'resend') {
      dispatch(setVerificationMethod(type));
      const reqAction = await dispatch(
        UserManagementApi.requestOTP({ ...formValues, type: type ? type : verificationMethod })
      );
      if (UserManagementApi.requestOTP.fulfilled.match(reqAction)) {
        console.log('TODO handle success');
        // TODO handle hint
        // hint = selectedType === VerificationTypes.CALL ? 'Call in progress' : reqAction.payload.data.success.message;
      }
      if (UserManagementApi.requestOTP.rejected.match(reqAction)) {
        reqAction?.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: reqAction.payload?.error?.message,
          type: 'error',
        });
      }
      
    
    }
  };

  const isMobile = useResponsive('down', 'md');
  const isDesktop = useResponsive('up', 'lg');

  if (isLoading) {
    return (
      <Container sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <CirclesWithBar
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel="circles-with-bar-loading"
        />
      </Container>
    );
  }
  // TODO is firstime needed?
  return (
    <Page title="Login" sx={{ display: 'table', width: '100%' }}>
      {!!loginError && loginError.error && (
        <CommonMessageModal
          message={loginError.error.message}
          open={openErrorDialog}
          onClose={() => {
            if (loginError.isLogOut) {
              logout();
            } else {
              dispatch(setLoginError(undefined));
            }
            setOpenErrorDialog(false);
          }}
          showCloseIcon={true}
        />
      )}
      {openSnackbar.open && (
          <CustomSnackbar
            open={openSnackbar.open}
            severityType={openSnackbar.type}
            message={openSnackbar.message as string}
            header={openSnackbar.message_header}
            onClose={() => {
              setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
            }}
          />
        )}
      <StyledMainCard>
        <Grid container sx={{ marginTop: 0 }} height="100%">
          <Grid item xs={12} sm={12} md={5} lg={6} xl={6} order={{ xs: 2, sm: 2, md: 1 }}>
            {isMobile && (
              <Box
                sx={{
                  maxHeight: '100%',
                }}
              >
                <img
                  src={currentUserProps?.mobile_bg_image}
                  alt="logo"
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Box>
            )}
            {!isMobile && (
              <StyledDiv
                image={
                  isMobile
                    ? currentUserProps?.mobile_bg_image
                    : isDesktop
                    ? currentUserProps?.desktop_bg_image
                    : currentUserProps?.image
                }
              ></StyledDiv>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} xl={6} order={{ xs: 1, sm: 1, md: 2 }}>
            <StyledRightbox>
              <Box
                sx={{
                  textAlign: 'left',
                  marginBottom: 3,
                  maxWidth: '220px',
                }}
              >
                <img
                  src={currentUserProps?.icon}
                  alt="logo"
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Box>

              {loginData?.is_otp_required && !!loginData?.phone_number ? (
                <>
                  <Grid item xs={12}>
                    <Typography>{`Enter the verification code you received to ${loginData.phone_number}`}</Typography>
                    <InputOTP
                      numInputs={5}
                      onSubmit={(otp) =>
                        verifyLoginOTP({ ...formValues, otp, type: verificationMethod })
                      }
                      separator={<Typography>-</Typography>}
                    />
                  </Grid>
                  <Grid item sx={{ marginTop: 3 }}>
                    <StyledLinks
                      variant="text"
                      onClick={() => handleRequestOTP(undefined, 'resend')}
                    >
                      Resend OTP
                    </StyledLinks>
                  </Grid>
                  <Grid item sx={{ marginTop: 1.5 }}>
                    <StyledLinks onClick={() => handleRequestOTP(VerificationTypes.CALL)}>
                      Call Me
                    </StyledLinks>
                  </Grid>
                  <Grid item sx={{ marginTop: 1.5 }}>
                    <StyledLinks
                      onClick={() =>
                        handleRequestOTP(
                          verificationMethod === VerificationTypes.SMS
                            ? VerificationTypes.EMAIL
                            : VerificationTypes.SMS
                        )
                      }
                    >
                      {`Send to ${
                        verificationMethod === VerificationTypes.SMS ? 'Email' : 'Phone'
                      } instead`}
                    </StyledLinks>
                  </Grid>
                </>
              ) : (
                <LoginForm onSubmit={handleOnSubmit} title={currentUserProps?.title} />
              )}
              {currentUserProps?.name === 'FB' && (
                <FacebookLoginSection info={currentUserProps.info} isMobile={isMobile} />
              )}
            </StyledRightbox>
          </Grid>
        </Grid>
      </StyledMainCard>
    </Page>
  );
};

export default LoginPage;
