import { ReactNode } from 'react';
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { CirclesWithBar } from 'react-loader-spinner';

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  allowedTenants: string[];
  children: ReactNode;
};

export default function RoleBasedGuard({
  accessibleRoles,
  allowedTenants,
  children,
}: RoleBasedGuardProp) {
  const { role, isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <Container sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <CirclesWithBar
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel="circles-with-bar-loading"
        />
      </Container>
    );
  }
  if (
    isAuthenticated &&
    !accessibleRoles.some(
      (r) => role === r || !allowedTenants.includes(process.env.REACT_APP_TENANT_NAME as string)
    )
  ) {
    return <Navigate to={'/404'} />;
  }

  return <>{children}</>;
}
