export const VALIDATE_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})/;
export const NAME_VALIDATION = /^[a-zA-Z]+ [a-zA-Z]+$/;
export const BANk_PATTERN_VALIDATION = /^[a-zA-Z0-9 \s]+$/;
export const INPUT_EMAIL_VALIDATION = /[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const COMPANY_EMAIL_VALIDATION = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/;
export const CITY_VALIDATION = /^[a-zA-Z-\s]+$/;
export const ABN_NUMBER_VALIDATION = (v: any) => {
  let formattedNum = v
    .toString()
    .replace(/\s/g, '')
    .replace(/^(.{2})(.{3})(.{3})(.{3})(.*)$/, '$1 $2 $3 $4');
  return formattedNum;
};
export const safeNumberOrDecimal = (v: any) => {
  if (typeof v === 'number') return v;
  if (typeof v === 'string' && /^[0-9,]*$/g.test(v)) return parseFloat(v);
  return 0.0;
};
