import { createSlice } from '@reduxjs/toolkit';
import { UserManagementState } from '../userManagement/userManagement.type';
import { UserManagementApi } from 'api';

const initialState: UserManagementState = {
  isLoading: false,
};

const userManagementSlice = createSlice({
  name: 'userManagementSlice',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      return { ...initialState };
    },
    setUserMgmError: (state, action) => {
      state.userError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserManagementApi.resetPassword.pending, (state) => {
        state.isLoading = true;
        state.resetPasswordResponse = undefined;
      })
      .addCase(UserManagementApi.resetPassword.fulfilled, (state, action) => {
        state.resetPasswordResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.resetPassword.rejected, (state, action) => {
        state.userError = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.getSellerAgreement.pending, (state) => {
        state.userProfileData = undefined;
        state.isLoading = true;
      })
      .addCase(UserManagementApi.getSellerAgreement.fulfilled, (state, action) => {
        state.sellerAgreementDoc = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.getSellerAgreement.rejected, (state, action) => {
        state.userError = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.validateEmailPost.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(UserManagementApi.getCompanyProfile.pending, (state) => {
        state.isLoading = true;
        state.companyProfileData = undefined;
      })
      .addCase(UserManagementApi.getCompanyProfile.fulfilled, (state, action) => {
        state.companyProfileData = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.getCompanyProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(UserManagementApi.updateCompanyProfile.fulfilled, (state, action) => {
        state.companyProfileData = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.updateCompanyProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(UserManagementApi.getUserProfile.pending, (state) => {
        state.isLoading = true;
        state.userProfileData = undefined;
      })
      .addCase(UserManagementApi.getUserProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(UserManagementApi.updateUserProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(UserManagementApi.getSecurityQuestionsList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(UserManagementApi.getSecurityQuestionsList.fulfilled, (state, action) => {
        state.securityQData = action.payload;
        state.isLoading = false;
      })
      .addCase(UserManagementApi.getSecurityQuestionsList.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setUserMgmError } = userManagementSlice.actions;

export default userManagementSlice.reducer;
