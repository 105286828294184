import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as Yup from 'yup';
import { InputLabel, TextField, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { BasicSignupPayload } from 'redux/login/login.type';
import FormikSelect from 'components/formik/FormikSelect';
import FormikTextField from 'components/formik/FormikTextField';
import { useSelector } from 'redux/store';
import countryList from 'country-codes-list';
import AutoComplete, { usePlacesWidget } from 'react-google-autocomplete';
import { useState } from 'react';

interface OwnProps {
  onSubmit: (value: Partial<BasicSignupPayload>) => void;
  handleCountrySearch: (code: string) => void;
  init: Partial<BasicSignupPayload>;
}
const ValidationSchema = Yup.object().shape({
  address1: Yup.string().required('Required'),
  address2: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  zipcode: Yup.string().required('Required'),
});

const AddressRegStep = ({ onSubmit, handleCountrySearch, init }: OwnProps) => {
  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const { stateList } = useSelector((state) => state.common);

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_MAPS_API_KEY,
  //   onPlaceSelected: (place: any) => console.log(place),
  // });

  // const { ref: address2Ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_MAPS_API_KEY,
  //   onPlaceSelected: (place: any) => console.log(place),
  // });
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form autoComplete="off" onSubmit={props.handleSubmit}>
          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={'30px'}
              >
                What is your company's address?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field
                component={FormikSelect}
                name={`country`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Country</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
              >
                {countryList.all().map((p, index) => (
                  <MenuItem
                    key={index}
                    value={p.countryCode}
                    onClick={() => {
                      handleCountrySearch(p.countryCode as string);
                    }}
                  >
                    {p.countryNameEn}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Address 1</span>}
                name={`address1`}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                name="address2"
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Address 2</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                name={`city`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>City</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
              <Field
                component={FormikTextField}
                name={`zipcode`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Zip Code</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
              <Field
                component={FormikSelect}
                name={`state`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>State</span>}
                fontSize={'16px'}
                disabled={!props.values.country}
              >
                {!!stateList &&
                  stateList.results?.map((p, index) => (
                    <MenuItem key={index} value={p.region_name}>
                      {p.region_name}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddressRegStep;
