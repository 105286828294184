import { createSlice } from '@reduxjs/toolkit';
import { InvoiceState } from '../invoice/invoice.type';
import { InvoiceApi, SellInvoiceApi } from 'api';

const initialState: InvoiceState = {
  isLoading: false,
  isInvoiceLoading: false,
  isInvoiceFilterLoading: false,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInvoiceError: (state, action) => {
      state.invoiceError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(InvoiceApi.getInvoiceFilterData.pending, (state, action) => {
        state.isInvoiceFilterLoading = true;
        state.invoiceFilterData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceFilterData.fulfilled, (state, action) => {
        state.invoiceFilterData = action.payload;
        state.isInvoiceFilterLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceFilterData.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isInvoiceFilterLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceData.pending, (state, action) => {
        state.isInvoiceLoading = true;
        state.invoiceData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceData.fulfilled, (state, action) => {
        state.invoiceData = action.payload;
        state.isInvoiceLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceData.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isInvoiceLoading = false;
      })
      .addCase(InvoiceApi.deleteInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(InvoiceApi.deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(InvoiceApi.deleteInvoice.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceDetails.pending, (state, action) => {
        state.isLoading = true;
        state.invoiceDetailData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetailData = action.payload;
      })
      .addCase(InvoiceApi.getInvoiceDetails.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.pending, (state, action) => {
        state.isLoading = true;
        state.buyerSellerDetailData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerSellerDetailData = action.payload;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.pending, (state, action) => {
        state.isLoading = true;
        state.buyerSellerDetailData = undefined;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerSellerDetailData = action.payload;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })

      .addCase(InvoiceApi.getOfferScheduleDetails.pending, (state, action) => {
        state.isLoading = true;
        state.invoiceDetailsRecurringData = undefined;
      })
      .addCase(InvoiceApi.getOfferScheduleDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetailsRecurringData = action.payload;
      })
      .addCase(InvoiceApi.getOfferScheduleDetails.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setInvoiceIsLoading, setInvoiceError } = invoiceSlice.actions;

export default invoiceSlice.reducer;
