import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  InvoiceContactsVisibilityBuyerSellerData,
  ContactsRegistrationStepsData,
  DirectListData,
  CompanySearchResponse,
  DirectListResultData,
} from 'redux/contacts/contacts.type';
import {
  ServerErrors,
  CommonSuccessResponse,
  CreateDirectoryResponse,
} from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getInvoiceContactsVisibilitySeller = createAsyncThunk<
  InvoiceContactsVisibilityBuyerSellerData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceContactsVisibilitySeller', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceContactsVisibilityBuyerSellerData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_contacts_visibility_seller/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getInvoiceContactsVisibilityBuyer = createAsyncThunk<
  InvoiceContactsVisibilityBuyerSellerData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceContactsVisibilityBuyer', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceContactsVisibilityBuyerSellerData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_contacts_visibility_buyer/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const getInvoiceContactsVisibilityFilter = createAsyncThunk<
  ContactsRegistrationStepsData,
  void,
  { rejectValue: ServerErrors }
>('getInvoiceContactsVisibilityFilter', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<ContactsRegistrationStepsData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_contacts_visibility/list/filter/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
//TODO q=user ?spinner=off&user_type=seller&q=st
const getDirectoryList = createAsyncThunk<DirectListData, string, { rejectValue: ServerErrors }>(
  'getDirectoryList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<DirectListData>(
        `${process.env.REACT_APP_SERVER_URL}directory/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check payload, params
const addDirectory = createAsyncThunk<
  CreateDirectoryResponse,
  { params: string; payload: Partial<DirectListResultData> },
  { rejectValue: ServerErrors }
>('addDirectory', async (query, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CreateDirectoryResponse>(
      `${process.env.REACT_APP_SERVER_URL}directory/${query.params}`,
      { ...query.payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check response
const getCompanyDirectoryList = createAsyncThunk<
  CompanySearchResponse,
  string,
  { rejectValue: ServerErrors }
>('getCompanyDirectoryList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<CompanySearchResponse>(
      `${process.env.REACT_APP_SERVER_URL}directory/company/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check payload, params
const deleteDirectory = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteDirectory',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<any>(
        `${process.env.REACT_APP_SERVER_URL}directory/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check payload, params
const editDirectory = createAsyncThunk<
  CreateDirectoryResponse,
  { params: string; payload: Partial<DirectListResultData> },
  { rejectValue: ServerErrors }
>('editDirectory', async (query, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CreateDirectoryResponse>(
      `${process.env.REACT_APP_SERVER_URL}directory/${query.params}`,
      { ...query.payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO ADD customer

export default {
  getInvoiceContactsVisibilitySeller,
  getInvoiceContactsVisibilityBuyer,
  getInvoiceContactsVisibilityFilter,
  getDirectoryList,
  addDirectory,
  getCompanyDirectoryList,
  deleteDirectory,
  editDirectory,
};
