import React from 'react';
import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface OwnProps {
  type?: string;
  title?: string;
  message?: string;
  handleCloseNotification: () => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  display: 'block',
  padding: '20px',
  maxWidth: '480px',
  border: '2px solid',
  marginBottom: '10px',
  background: 'transparent',
  [theme.breakpoints.down('sm')]: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '10px',
  },
}));

const Notifications = ({ type, title, message, handleCloseNotification }: OwnProps) => {
  return (
    <>
      <StyledCard
        sx={{
          ...(type === 'success' && {
            color: (theme) => theme.palette.success.main,
            borderColor: (theme) => theme.palette.success.main,
          }),
          ...(type === 'information' && {
            color: (theme) => theme.palette.info.main,
            borderColor: (theme) => theme.palette.info.main,
          }),
          ...(type === 'warning' && {
            color: (theme) => theme.palette.warning.main,
            borderColor: (theme) => theme.palette.warning.main,
          }),
          ...(type === 'error' && {
            color: (theme) => theme.palette.error.main,
            borderColor: (theme) => theme.palette.error.main,
          }),
          ...(type === 'custom' && {
            color: (theme) => theme.palette.others?.main,
            borderColor: (theme) => theme.palette.primary.main,
          }),
        }}
      >
        {type !== 'custom' && (
          <Grid container>
            <Grid item xs={2} lg={1} alignItems={'center'}>
              {(type === 'success' && <CheckCircleOutlineIcon />) ||
                (type === 'information' && <InfoOutlinedIcon />) ||
                (type === 'warning' && <WarningAmberOutlinedIcon />) ||
                (type === 'error' && <ErrorOutlineOutlinedIcon />)}
            </Grid>
            <Grid item xs={10} lg={11}>
              <Box
                sx={{
                  marginBottom: '10px',
                  color: (theme) => theme.palette.secondary.contrastText,
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="body2">{title}</Typography>
                  <CloseOutlinedIcon onClick={handleCloseNotification} />
                </Stack>
              </Box>
              <Box
                sx={{ textAlign: 'left', color: (theme) => theme.palette.secondary.contrastText }}
              >
                <Typography variant="body2">{message}</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {type === 'custom' && (
          <>
            <Box sx={{ marginBottom: '10px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="body2">{title}</Typography>
                <CloseOutlinedIcon onClick={handleCloseNotification} />
              </Stack>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body2">{message}</Typography>
            </Box>
          </>
        )}
      </StyledCard>
    </>
  );
};

export default Notifications;
