import React from 'react';
import { Typography } from '@mui/material';
import {
  AngelsLoginImage,
  CityLoginImage,
  FBLoginImage,
  CODLoginImage,
  CrowdzLoginImage,
  CityMainLogo,
  CrowdzMainLogo,
  CODMainLogo,
  AvalonMarketplace,
  login_bg_desktop,
  login_bg_mobile,
} from 'assets';

export interface SignUp {
  title: string;
  description?: React.ReactNode;
  policy?: string;
}
export interface LoginPropsData {
  name: string;
  title: string;
  description?: string;
  icon: string;
  image: string;
  desktop_bg_image?: string;
  mobile_bg_image?: string;
  info?: string;
  signUp: SignUp;
  contactEmail: string;
}

export const authProps = [
  {
    name: 'City',
    title: 'Log In to Your Account',
    icon: CityMainLogo,
    image: CityLoginImage,
    contactEmail: 'todo@todo.com',
    signUp: {
      title: 'Hello',
      description: `Welcome to Citibank Whitelabel.${(<br />)} Let's start with your company.${(
        <br />
      )} What is your company email address?`,
      policy: `By submitting your application, you agree that Crowdz (Agora Intelligence Inc.) may provide your information to Citi Platforms, Inc for the purposes of contacting you about your experience with this program or for marketing related opportunities. You may withdraw your consent and unsubscribe from these at any time, for example by clicking the unsubscribe link in our emails. For more information about how Citi handles your data please read ou`,
    },
    description:
      'Citibank Whitelabel offers an affordable, immediate way to get paid on your customers receivables.',
  },
  {
    name: 'COD',
    title: 'Log In to Your Account',
    icon: CODMainLogo,
    image: CODLoginImage,
    contactEmail: 'todo@todo.com',
    signUp: {
      title: 'Hello',
      description: `Welcome to Meta Invoice Fast Track.${(
        <br />
      )} Let's start with your company. ${(<br />)}What is your company email address?`,
    },
    description:
      'ProFinCo Capital and Cash Flow Program offers an affordable, immediate way to get paid on your customers receivables.',
  },
  {
    name: 'Angelsden',
    title: 'Log In to Your Account',
    icon: '',
    contactEmail: 'todo@todo.com',
    signUp: {
      title: 'Hello',
      description: `Welcome to the Angels Den Finance Program.${(
        <br />
      )} Let's start with your company. ${(<br />)}What is your company email address?`,
    },
    image: AngelsLoginImage,
  },
  {
    name: 'Avalon Core',
    title: 'Log In to Your Account',
    icon: AvalonMarketplace,
    image: CrowdzLoginImage,
    desktop_bg_image: login_bg_desktop,
    mobile_bg_image: login_bg_mobile,
    contactEmail: 'todo@todo.com',
    signUp: {
      title: '',
      description: (
        <>
          <Typography
            variant="body2"
            fontSize={'16px'}
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            Welcome to Avalon Marketplace Powered by Crowdz.
          </Typography>
          <Typography
            variant="body2"
            fontSize={'16px'}
            fontWeight={(theme) => theme.typography.fontWeightBold}
            marginTop={2}
          >
            Let's start with your company. What is your company email address?`
          </Typography>
        </>
      ),
      policy: `By submitting your application, you agree that Crowdz (Agora Intelligence Inc.) may
      provide your information to Crowdz Platforms, Inc for the purposes of contacting you about your experience with
      this program or for marketing related opportunities. You may withdraw your consent and unsubscribe from these
      at any time, for example by clicking the unsubscribe link in our emails. For more information about how Crowdz
      handles your data please read our `,
    },
  },
  {
    name: 'EG',
    title: 'Log In to Your Account',
    icon: '',
    image: FBLoginImage,
    contactEmail: 'todo@todo.com',
    info: 'Watch how real small businesses are using the program',
    signUp: {
      title: 'Hello',
      description: (
        <Typography variant="h6">
          Welcome to Meta Invoice Fast Track.
          <br />
          Let's start with your company.
          <br />
          What is your company email address?
        </Typography>
      ),
      policy: `By submitting your application, you agree that Crowdz (Agora Intelligence Inc.) may provide your information to Meta Platforms, Inc for the purposes of contacting you about your experience with this program or for marketing related opportunities. You may withdraw your consent and unsubscribe from these at any time, for example by clicking the unsubscribe link in our emails. For more information about how Meta handles your data please read our`,
    },
    description:
      'Meta Invoice Fast Track provides affordable, immediate cash for pay that your customers owe you.',
  },
  {
    name: 'FB',
    title: 'Log In to Your Account',
    contactEmail: 'todo@todo.com',
    icon: '',
    image: FBLoginImage,
    info: 'Watch how real small businesses are using the program',
    signUp: {
      title: 'Hello',
      description: (
        <Typography variant="h6">
          Welcome to Meta Invoice Fast Track.
          <br />
          Let's start with your company.
          <br />
          What is your company email address?
        </Typography>
      ),
      policy: `By submitting your application, you agree that Crowdz (Agora Intelligence Inc.) may provide your information to Meta Platforms, Inc for the purposes of contacting you about your experience with this program or for marketing related opportunities. You may withdraw your consent and unsubscribe from these at any time, for example by clicking the unsubscribe link in our emails. For more information about how Meta handles your data please read our`,
    },
    description:
      'Meta Invoice Fast Track provides affordable, immediate cash for pay that your customers owe you.',
  },
];
