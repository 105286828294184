import {
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  Link,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import SettingsBreadCrumbs from 'components/breadCrumbs/SettingsBreadCrumbs';
import FormikTextField from 'components/formik/FormikTextField';
import { ErrorMessage, Field, Form, Formik, FormikProps, useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'redux/store';
import { useEffect, useRef, useState } from 'react';
import { SettingsApi } from 'api';
import useAuth from 'hooks/useAuth';
import Container from '@mui/material/Container';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FinancialProfileData } from '../../redux/settings/settings.type';
import ConfirmationModal from 'components/Models/ConfirmationModal';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import CustomSnackbar from 'components/CustomSnackbar';
import * as Yup from 'yup';
import InformationModal from 'components/Models/InformationModal';
import Page from 'components/Page';

interface AutProps {
  value?: number;
}

// TODO fix Nan
const ValidationSchema = Yup.object().shape({
  annual_revenue: Yup.string().required('This field is required.'),
  current_liabilities: Yup.string().required('This field is required.'),
  long_term_liabilities: Yup.string().required('This field is required.'),
  current_shareholders_equity: Yup.string().required('This field is required.'),
  highest_current_credit_line: Yup.string().required('This field is required.'),
  ebitda: Yup.string().required('This field is required.'),
  all_revenue: Yup.string().required('This field is required.'),
  all_expenses: Yup.string().required('This field is required.'),
  interest_expense: Yup.string().required('This field is required.'),
  taxes_paid: Yup.string().required('This field is required.'),
  depreciation_taken: Yup.string().required('This field is required.'),
  amortization: Yup.string().required('This field is required.'),
});

const AutoSubmitToken = ({ value }: AutProps) => {
  // Grab values and submitForm from context
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (!!value) setFieldValue('ebitda', value);
  }, [value, values, setFieldValue]);
  return null;
};
const FinancialProfilePage = () => {
  const [openEbitda, setOpenEbitda] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const breadCrumbs = { name: 'Settings/Financial profile', path: '#' };
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const { financialProfileData, isLoading } = useSelector((state) => state.settings);
  const [openContactSupportModal, setContactSupport] = useState(false);

  const [init, setInit] = useState<number>();
  const { currency, isUserAllowedToAccess, registrationStepsData, tenantDetailData } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SettingsApi.getFinancialProfile());
  }, []);

  const contactSupport = () => {
    setContactSupport(true);
  };

  const updateFinancialProfile = async (values: any) => {
    const action = await dispatch(SettingsApi.updateFinancialProfile(values));
    if (SettingsApi.updateFinancialProfile.fulfilled.match(action)) {
      //TODO Show success message
    }
    if (SettingsApi.updateFinancialProfile.rejected.match(action)) {
      // TODO show error
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
    }
  };

  const handleCalculate = async (values: FinancialProfileData) => {
    const payload = {
      all_revenue: values.annual_revenue,
      all_expenses: values.all_expenses,
      interest_expense: values.interest_expense,
      taxes_paid: values.taxes_paid,
      depreciation_taken: values.depreciation_taken,
      amortization: values.amortization,
    };
    const action = await dispatch(SettingsApi.calculateEDITDA(payload));
    if (SettingsApi.calculateEDITDA.fulfilled.match(action)) {
      //console.log('>>>>>', action.payload);
    }
    if (SettingsApi.calculateEDITDA.rejected.match(action)) {
      // TODO show error
      // console.log(action.payload);
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
    }
  };
  let actionType = '';
  const handleSaveLater = () => {
    // ';
  };

  if (isLoading) {
    return (
      <Container sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Page title="Financial profile">
      <ConfirmationModal
        message="Are You Sure You want to save and finish later"
        onClose={() => {
          setOpenConfirm(false);
        }}
        open={openConfirm}
        title=""
        //  isHideActions?: boolean;
        // children={<Button onClick={() => {}}> Yes</Button>}
        agreeButtonTitle="Yes"
        cancelButtonTitle="Continue"
      />
      <Stack mb={4}>
        <SettingsBreadCrumbs data={breadCrumbs} />
      </Stack>
      <Formik
        initialValues={financialProfileData || {}}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append('data', JSON.stringify(values));
          updateFinancialProfile(formData);
        }}
      >
        {(formProps) => (
          <Form>
            <Grid container columnSpacing={4} rowGap={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={FormikTextField}
                  label="Annual Revenue For Last Full Year"
                  placeholder="00.00"
                  currency={currency}
                  InputLabelProps={{ shrink: true }}
                  name={`annual_revenue`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  textFieldProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="annual_revenue"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={FormikTextField}
                  placeholder="00.00"
                  currency={currency}
                  InputLabelProps={{ shrink: true }}
                  label="Current Liabilities"
                  name={`current_liabilities`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  textFieldProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="current_liabilities"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={FormikTextField}
                  placeholder="00.00"
                  currency={currency}
                  InputLabelProps={{ shrink: true }}
                  label="Long term Liabilities"
                  name={`long_term_liabilities`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  textFieldProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="long_term_liabilities"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={FormikTextField}
                  placeholder="00.00"
                  currency={currency}
                  disabled={!isUserAllowedToAccess}
                  InputLabelProps={{ shrink: true }}
                  label="Current Shareholder Equity"
                  name={`current_shareholders_equity`}
                  fontSize={'14px'}
                  textFieldProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="current_shareholders_equity"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={FormikTextField}
                  placeholder="00.00"
                  currency={currency}
                  disabled={!isUserAllowedToAccess}
                  InputLabelProps={{ shrink: true }}
                  label="Highest Credit Line"
                  name={`highest_current_credit_line`}
                  fontSize={'14px'}
                  textFieldProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="highest_current_credit_line"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
                <AutoSubmitToken value={init} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack direction="row">
                  <Field
                    component={FormikTextField}
                    placeholder="00.00"
                    currency={currency}
                    disabled={!isUserAllowedToAccess}
                    InputLabelProps={{ shrink: true }}
                    label="EBITDA"
                    name={`ebitda`}
                    fontSize={'14px'}
                    textFieldProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                          <Typography>{currency}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    sx={{
                      textDecoration: 'none',
                      fontSize: '10px',
                      color: 'inherit',
                      cursor: 'pointer',
                      minWidth: '80px',
                    }}
                    onClick={() => {
                      setOpenEbitda(true);
                    }}
                  >
                    {`Don't have this?`}
                  </Link>
                  <ErrorMessage
                    name="ebitda"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            {openEbitda && (
              <Stack sx={{ marginTop: 4 }}>
                <Typography variant="h6" sx={{ fontSize: '13px', marginBottom: 2 }}>
                  FILL IN THE FOLLOWING INFORMATION AND WE WILL CALCULATE YOUR EBITDA FOR YOU
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="All Revenue For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`all_revenue`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="all_revenue"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="All Expenses For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`all_expenses`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="all_expenses"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="Interest expanse For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`interest_expense`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="interest_expense"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="Tax Paid For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`taxes_paid`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="taxes_paid"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="Depreciation taken For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`depreciation_taken`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="depreciation_taken"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={FormikTextField}
                      label="Amortization For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      InputLabelProps={{ shrink: true }}
                      name={`amortization`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      textFieldProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="amortization"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
            <Stack direction={'row'} justifyContent="flex-end">
              {openEbitda && (
                <Button
                  variant="contained"
                  // type="submit"
                  onClick={() => {
                    handleCalculate(formProps.values);
                  }}
                  sx={{ marginTop: '50px' }}
                >
                  Calculate
                </Button>
              )}
              {!tenantDetailData?.accounting_system_mandatory && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: '50px', marginRight: 1 }}
                >
                  update
                </Button>
              )}

              {registrationStepsData?.filter((f) => f.step_number === 11 && f.is_completed)
                .length === 0 &&
                !tenantDetailData?.accounting_system_mandatory && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenConfirm(true);
                    }}
                    sx={{ marginTop: '50px', marginRight: 1 }}
                  >
                    Save and finish later
                  </Button>
                )}
              {tenantDetailData?.accounting_system_mandatory && (
                <Button onClick={contactSupport}>Contact Customer Support</Button>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}

      {openContactSupportModal && (
        <InformationModal open={openContactSupportModal} onClose={() => setContactSupport(false)}>
          Please contact <br /> support@receivablesfinance.com
        </InformationModal>
      )}
    </Page>
  );
};
export default FinancialProfilePage;
