// TODO review it
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServerErrors } from 'redux/common/common.type';
import {
  InvoiceData,
  InvoiceFilterData,
  CreateInvoiceRes,
  InvoiceHistoryListData,
  InvoiceDetailsData,
  AuctionCategoryListData,
  AuctionListData,
  AuctionDetailsData,
  CreateOfferPayload,
  BuyerDetailData,
  InvoiceForPaymentResponse,
  InvoicePaymentPostResponse,
  BuyerDetails,
  InvoiceDetailsRecurring,
  CreateInvoiceObj,
} from 'redux/invoice/invoice.type';
import axios, { handleServerErrors } from 'utils/axios.config';
import { getQueryOrEmpty } from 'utils/common';
import { AcceptOfferPayload } from '../redux/invoice/invoice.type';
import { CommonSuccessResponse } from '../redux/common/common.type';

// TODO
const createInvoice = createAsyncThunk<
  CreateInvoiceRes,
  { params: string; payload: CreateInvoiceObj },
  { rejectValue: ServerErrors }
>('createInvoice', async (payloadData, { rejectWithValue }) => {
  try {
    const { params, payload } = payloadData;
    const { data } = await axios.post<CreateInvoiceRes>(
      `${process.env.REACT_APP_SERVER_URL}invoiceexchange/create_invoice/${params}`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO
const editInvoice = createAsyncThunk<any, CreateInvoiceObj, { rejectValue: ServerErrors }>(
  'editInvoice',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/update_invoice/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const downloadMassUploadErrorReport = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'downloadMassUploadErrorReport',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/mass_upload_errors/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, params
const getInvoiceDetails = createAsyncThunk<
  InvoiceDetailsData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceDetailsData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
const getOfferScheduleDetails = createAsyncThunk<
  InvoiceDetailsRecurring,
  string,
  { rejectValue: ServerErrors }
>('getOfferScheduleDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceDetailsRecurring>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review it, check response, payload
const changeInvoiceStatus = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'changeInvoiceStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/change_status/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const changeBulkInvoiceStatus = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'changeBulkInvoiceStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/change_status/bulk`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const getFileStatus = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getFileStatus',
  async (url, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(url);
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const selectAllInvoices = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'selectAllInvoices',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/select-all/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const acceptBid = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'acceptBid',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/bid/accept/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const getBidList = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getBidList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const requestBestBid = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'requestBestBid',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/request-final/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
// TODO review it, check response, payload
const invoiceRepaymentComplete = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'invoiceRepaymentComplete',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/repayment/complete/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response
const getInvoiceSellBuyerDetail = createAsyncThunk<
  BuyerDetailData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceSellBuyerDetail', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<BuyerDetailData>(
      `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/buyer_details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, payload
const updateInvoiceSellBuyerDetail = createAsyncThunk<
  BuyerDetails,
  { query: string; payload: Partial<BuyerDetails> },
  { rejectValue: ServerErrors }
>('updateInvoiceSellBuyerDetail', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<BuyerDetails>(
      `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/buyer_details/${params.query}`,
      params.payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review
const getInvoiceBuyerDetail = createAsyncThunk<
  BuyerDetailData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceBuyerDetail', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<BuyerDetailData>(
      `${process.env.REACT_APP_SERVER_URL}invoices/invoice/buyer_details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getInvoiceForPayment = createAsyncThunk<
  InvoiceForPaymentResponse,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceForPayment', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceForPaymentResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/payments/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const initiatePayment = createAsyncThunk<
  InvoicePaymentPostResponse,
  any,
  { rejectValue: ServerErrors }
>('initiatePayment', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<InvoicePaymentPostResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/payments/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review
const updateInvoiceBuyerDetail = createAsyncThunk<
  BuyerDetailData,
  { id: number; payload: Partial<BuyerDetailData> },
  { rejectValue: ServerErrors }
>('updateInvoiceBuyerDetail', async (query, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<BuyerDetailData>(
      `${process.env.REACT_APP_SERVER_URL}invoices/invoice/buyer_details/?buyer_id=${query.id}`,
      { ...query.payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review
const deleteInvoice = createAsyncThunk<
  any,
  {
    type: string;
    payload: any;
  },
  { rejectValue: ServerErrors }
>('deleteInvoice', async ({ type, payload }, { rejectWithValue }) => {
  try {
    if (type === 'delete') {
      const { data } = await axios.put<Response>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/delete/`,
        { ...payload }
      );
      return data;
    } else {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/archive/`,
        { ...payload }
      );
      return data;
    }
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review
const getInvoiceHistory = createAsyncThunk<
  InvoiceHistoryListData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceHistory', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceHistoryListData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/history/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review
const setStatusViewed = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'setStatusViewed',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/status-viewed/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response
const getInvoiceAuctionData = createAsyncThunk<
  AuctionListData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceAuctionData', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<AuctionListData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response
const getInvoiceApprovalQueueList = createAsyncThunk<
  AuctionCategoryListData,
  void,
  { rejectValue: ServerErrors }
>('getInvoiceApprovalQueueList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<AuctionCategoryListData>(
      `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/auction/categories/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getInvoiceData = createAsyncThunk<InvoiceData, string, { rejectValue: ServerErrors }>(
  'getInvoiceListData',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<InvoiceData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getScheduleRepayment = createAsyncThunk<InvoiceData, string, { rejectValue: ServerErrors }>(
  'getScheduleRepayment',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<InvoiceData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getInvoiceFilterData = createAsyncThunk<
  InvoiceFilterData,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceFilterData', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<InvoiceFilterData>(
      `${process.env.REACT_APP_SERVER_URL}exchange-ui/dashboard/invoice/filters/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, and payload
const archiveAuctions = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'archiveAuctions',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/archive/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, and params
const getAuctionDetail = createAsyncThunk<
  AuctionDetailsData,
  string,
  { rejectValue: ServerErrors }
>('getAuctionDetail', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<AuctionDetailsData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const createSellerInvoiceOffer = createAsyncThunk<
  CommonSuccessResponse,
  CreateOfferPayload,
  { rejectValue: ServerErrors }
>('getAuctionDetail', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/create_offer/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const acceptSellerInvoiceOffer = createAsyncThunk<
  CommonSuccessResponse,
  AcceptOfferPayload,
  { rejectValue: ServerErrors }
>('acceptSellerInvoiceOffer', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/accept_offer/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getInvoiceHistory,
  setStatusViewed,
  getInvoiceBuyerDetail,
  deleteInvoice,
  updateInvoiceBuyerDetail,
  getInvoiceAuctionData,
  getInvoiceApprovalQueueList,
  archiveAuctions,
  getAuctionDetail,
  getInvoiceDetails,
  changeInvoiceStatus,
  getInvoiceData,
  getInvoiceFilterData,
  invoiceRepaymentComplete,
  requestBestBid,
  getBidList,
  acceptBid,
  selectAllInvoices,
  getFileStatus,
  changeBulkInvoiceStatus,
  downloadMassUploadErrorReport,
  editInvoice,
  createInvoice,
  createSellerInvoiceOffer,
  acceptSellerInvoiceOffer,
  getInvoiceForPayment,
  initiatePayment,
  getInvoiceSellBuyerDetail,
  updateInvoiceSellBuyerDetail,
  getOfferScheduleDetails,
  getScheduleRepayment,
};
