import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import { PATH_AUTH } from 'routes/paths';
// components
import Page from 'components/Page';

import Link from '@mui/material/Link';
import { authProps, LoginPropsData } from '../loginConfig';

import EmailAddressRegStep from './EmailAddressRegStep';
import PasswordRegStep from './PasswordRegStep';
import CompanyLegalNameRegStep from './CompanyLegalNameRegStep';
import AddressRegStep from './AddressRegStep';
import RegistrationProgressBar from './RegistrationProgressBar';
import SecurityQuestionRegStep from './SecurityQuestionRegStep';
import UserNameRegStep from './UserNameRegStep';
import PhoneNumberRegStep from './PhoneNumberRegStep';
import TermConditionRegStep from './TermConditionRegStep';
import { AvalonMarketplace } from 'assets';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import { CommonApi, LoginApi, UserManagementApi } from 'api';
import {
  EmailValidatePostPayload,
  CompanyValidationPayload,
  CreatePasswordPayload,
  CreateSecurityQuestionPayload,
  CreatePhoneNumberPayload,
  SendOTPPayload,
} from 'redux/userManagement/userManagement.type';
import { useDispatch, useSelector } from 'redux/store';
import { notification } from 'redux/slices/notification';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import InviteUserView from './InviteUserView';
import InviteLandingView from './InviteLandingView';
import InviteAcceptTerms from './InviteAcceptTerms';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { DASHBOARD_PAGES } from '../../../routes/paths';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import useAuth from 'hooks/useAuth';
import { setUserMgmError } from 'redux/slices/userManagement';
import { setLoginError } from 'redux/slices/login';
import { BasicSignupPayload, InvitedUserBasicSignUpPayload } from 'redux/login/login.type';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';

const screenHeight = window.innerHeight;
const screenWidth = window.innerWidth;

const StyledMainCard = styled(Card)(({ theme }) => ({
  maxHeight: '85vh',
  overflow: 'auto',
  maxWidth: '550px',
  padding: theme.spacing(5),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '20px',
  boxShadow: theme.shadows[5],

  // maxHeight: screenHeight - 50,
  // width: '100%',
  // height: '100%',
  // display: 'flex',
  // overflow: 'auto',
  // flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',
  // left: '50%',
  // top: '50%',
  // maxWidth: '650px',
  // position: 'absolute',
  // transform: 'translate(-50%, -50%)',
  // [theme.breakpoints.up('sm')]: {
  //   padding: '0 150px',
  //   borderRadius: '20px',
  //   boxShadow: theme.shadows[5],
  // },
  // [theme.breakpoints.between(401, 600)]: {
  //   padding: '0 80px',
  //   borderRaius: 0,
  //   backgroundColor: 'transparent',
  //   boxShadow: 'none',
  // },
  // [theme.breakpoints.between(320, 400)]: {
  //   padding: '0 30px',
  //   borderRaius: 0,
  //   backgroundColor: 'transparent',
  //   boxShadow: 'none',
  // },
}));
const StyledLink = styled(Link)(({ theme }) => ({
  paddingTop: 18,
  fontSize: 15,
  letterSpacing: 0.7,
}));
// TODO error handling, recaptcha error handling
const SignUp = () => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [basicPayload, setBasicPayload] = useState<Partial<BasicSignupPayload>>({});
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const { userError } = useSelector((state) => state.userManagement);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    initUserInfo,
    userInvitationDetail,
    loginError,
    tenantDetailData,
    isAuthenticated,
    sessionToken,
    keyInvitationVerify,
    invitedUserBasicSignUp,
    logout,
    handleVerifyUserEmailInvitationToken,
    keyInvitationValidation,
  } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const currentUserProps: LoginPropsData | undefined = authProps.filter(
    (f) => f.name === process.env.REACT_APP_TENANT_NAME
  )[0];

  useEffect(() => {
    if (params.token) {
      handleVerifyUserEmailInvitationToken(params.token);
    }
    if (params.key) {
      keyInvitationValidation({ key: params.key });
    }
    if (location && location.state) {
      setScreenIndex(location.state as number);
    }
    if (!isAuthenticated && !!sessionToken && screenIndex >= 4) {
      logout();
      navigate(PATH_AUTH.login);
    }
  }, []);

  useEffect(() => {
    // TODO error detail vs error message
    if (!!userError?.error || !!loginError?.error) {
      setOpenErrorDialog(true);
    }
  }, [userError, loginError]);

  const validateEmail = async (value: EmailValidatePostPayload) => {
    const validationEmailAction = await dispatch(
      UserManagementApi.validateEmailPost({ email_address: value.email_address })
    );
    if (UserManagementApi.validateEmailPost.fulfilled.match(validationEmailAction)) {
      setBasicPayload((prevState) => ({
        ...prevState,
        business_email: value.email_address,
        business_email_verify: value.verifyEmail,
      }));
      setScreenIndex(screenIndex + 1);
    }
    if (UserManagementApi.validateEmailPost.rejected.match(validationEmailAction)) {
      validationEmailAction?.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: validationEmailAction.payload?.error?.message,
          type: 'error',
        });
    }
  };

  const validateCompanyName = async (value: Partial<CompanyValidationPayload>) => {
    const validateCompanyNameAction = await dispatch(
      UserManagementApi.validateCompanyNamePost({
        email_address: basicPayload.business_email as string,
        company_name: value.company_name as string,
      })
    );
    if (UserManagementApi.validateCompanyNamePost.fulfilled.match(validateCompanyNameAction)) {
      setBasicPayload((prevState) => ({
        ...prevState,
        company_name: value.company_name,
      }));
      setScreenIndex(screenIndex + 1);
    }
    if (UserManagementApi.validateCompanyNamePost.rejected.match(validateCompanyNameAction)) {
      validateCompanyNameAction?.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: validateCompanyNameAction.payload?.error?.message,
          type: 'error',
        });
    }
  };

  const validateAndCreateBasicSignUp = async (value: Partial<BasicSignupPayload>) => {
    const validateBasicSignUpAction = await dispatch(
      LoginApi.createBasicSignup({
        first_name: value.first_name as string,
        last_name: value.last_name as string,
        country: basicPayload.country as string,
        address1: basicPayload.address1 as string,
        address2: basicPayload.address2 as string,
        company_name: basicPayload.company_name as string,
        business_email: basicPayload.business_email as string,
        business_email_verify: basicPayload.business_email_verify as string,
        zipcode: basicPayload.zipcode as string,
        city: basicPayload.city as string,
        state: basicPayload.state as string,
      })
    );
    if (LoginApi.createBasicSignup.fulfilled.match(validateBasicSignUpAction)) {
      setBasicPayload((prevState) => ({
        ...prevState,
        first_name: value.first_name,
        last_name: value.last_name,
      }));
      setScreenIndex(screenIndex + 1);
    }
    if (LoginApi.createBasicSignup.rejected.match(validateBasicSignUpAction)) {
      validateBasicSignUpAction?.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: validateBasicSignUpAction.payload?.error?.message,
          type: 'error',
        });
    }
  };

  const addAddress = async (value: Partial<BasicSignupPayload>) => {
    setBasicPayload((prevState) => ({
      ...prevState,
      ...value,
    }));
    setScreenIndex(screenIndex + 1);
  };

  const validateAndCreatePassword = async (value: CreatePasswordPayload) => {
    const validatePasswordAction = await dispatch(UserManagementApi.createUserPassword(value));
    if (UserManagementApi.createUserPassword.rejected.match(validatePasswordAction)) {
      dispatch(
        notification({
          open: true,
          message: `Create and validate password failed!`,
          type: 'error',
        })
      );
    } else {
      setScreenIndex(screenIndex + 1);
    }
  };

  const createSecurityQuestion = async (values: CreateSecurityQuestionPayload) => {
    let action = await dispatch(UserManagementApi.createSecurityQuestion(values));
    setScreenIndex(screenIndex + 1);
    if (UserManagementApi.createSecurityQuestion.rejected.match(action)) {
      action?.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: action.payload?.error?.message,
          type: 'error',
        });
    }
  };

  const validateAndCreatePhone = async (values: CreatePhoneNumberPayload) => {
    const validatePhoneAction = await dispatch(UserManagementApi.createPhoneNumber(values));
    if (UserManagementApi.createPhoneNumber.rejected.match(validatePhoneAction)) {
      dispatch(
        notification({
          open: true,
          message: `Invalid phone number or OPT!`,
          type: 'error',
        })
      );
    } else {
      setScreenIndex(screenIndex + 1);
    }
  };

  const sendOTP = async (values: SendOTPPayload) => {
    const validateOTPAction = await dispatch(UserManagementApi.requestOTP(values));
    if (UserManagementApi.requestOTP.rejected.match(validateOTPAction)) {
      dispatch(
        notification({
          open: true,
          message: `Invalid phone number or country code!`,
          type: 'error',
        })
      );
    }
  };

  // TODO handle the talking
  const updateUserLevel = async () => {
    const levelAction = await dispatch(LoginApi.updateUserLevel(null));
    if (LoginApi.updateUserLevel.fulfilled.match(levelAction)) {
      initUserInfo();
      dispatch(UserManagementApi.getSellerAgreement('?type=agreement'));
      navigate(DASHBOARD_PAGES.marketplace);
    }
    if (LoginApi.updateUserLevel.rejected.match(levelAction)) {
      // TODO show error
      levelAction?.payload?.error &&
      setOpenSnackbar({
        open: true,
        message: levelAction.payload?.error?.message,
        type: 'error',
      });
    }
  };
  // TODO
  const onUserVerified = useCallback(async () => {
    if (tenantDetailData?.recaptcha_enabled) {
      if (tenantDetailData?.captcha_public_key_v3 && executeRecaptcha) {
        try {
          const token = await executeRecaptcha('login');
          if (!token) {
            // TO fix error handling
            setOpenErrorDialog(true);
          } else {
            // navigate(DASHBOARD_PAGES.marketplace);
          }
        } catch (e) {
          console.log('TODO handle error', e.message);
        }
      } else {
        // TO fix error handling
        setOpenErrorDialog(true);
      }
    } else {
      //No captcha navigate
      navigate(DASHBOARD_PAGES.marketplace);
    }
  }, [executeRecaptcha]);

  const handleBasicSignUp = async (recaptcha?: string) => {
    if (!!params.key) {
      const basicInvitePayload = { key: params.key } as InvitedUserBasicSignUpPayload;
      if (tenantDetailData) {
        if (tenantDetailData.recaptcha_enabled) {
          basicInvitePayload.g_recaptcha_response = recaptcha;
        }
        const ActionsInvitedBasic = await dispatch(
          LoginApi.invitedUserBasicSignUp(basicInvitePayload)
        );
        if (LoginApi.invitedUserBasicSignUp.fulfilled.match(ActionsInvitedBasic)) {
          setScreenIndex(screenIndex + 1);
        }
        if (LoginApi.invitedUserBasicSignUp.rejected.match(ActionsInvitedBasic)) {
          // TODO show error
          ActionsInvitedBasic?.payload?.error &&
          setOpenSnackbar({
            open: true,
            message: ActionsInvitedBasic.payload?.error?.message,
            type: 'error',
          });
        }
        
      }
    }
  };

  const handleInvitedUserBasicSignUp = useCallback(async () => {
    if (tenantDetailData?.recaptcha_enabled) {
      if (tenantDetailData?.captcha_public_key_v3 && executeRecaptcha) {
        const token = await executeRecaptcha('login');
        if (!!token) {
          // TO fix error handling
          setOpenErrorDialog(true);
        } else {
          handleBasicSignUp(token);
        }
      } else {
        // TO fix error handling
        setOpenErrorDialog(true);
      }
    } else {
      handleBasicSignUp();
    }
  }, [executeRecaptcha]);
  const handleServerErrors = () => {
    if (loginError) {
      if (loginError.isLogOut) {
        logout();
        navigate(PATH_AUTH.login);
      } else {
        dispatch(setLoginError(undefined));
      }
    } else if (userError) {
      dispatch(setUserMgmError(undefined));
    }
    setOpenErrorDialog(false);
  };

  const handleCountrySearch = (code: string) => {
    dispatch(CommonApi.getStateData(code));
  };

  // TODO check is a user is invited(user_detail.is_invited), add success landing
  return (
    <Page
      title="Sign in"
      sx={{ backgroundColor: (theme) => theme.palette.customColors?.appBackground }}
    >
      <Container maxWidth="md">
        <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center' }}>
          {openErrorDialog && (
            <CommonMessageModal
              message={
                loginError?.error?.message ? loginError?.error?.message : userError?.error?.message
              }
              open={openErrorDialog}
              type="error"
              onClose={handleServerErrors}
              showCloseIcon={true}
            />
          )}
          <StyledMainCard>
            <Grid container>
              <Grid item>
                <img src={AvalonMarketplace} width="100%" height="100%" />
              </Grid>
            </Grid>
            {params.token && userInvitationDetail && tenantDetailData && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {screenIndex === 0 && (
                      <InviteUserView
                        handleNext={onUserVerified}
                        firstName={userInvitationDetail.first_name}
                        lastName={userInvitationDetail.last_name}
                        company={userInvitationDetail.company}
                        appName={tenantDetailData.app_name}
                        shortName={tenantDetailData.short_name}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {!!params.key && !!tenantDetailData && !!keyInvitationVerify && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {screenIndex === 0 && (
                      <InviteLandingView
                        handleNext={handleInvitedUserBasicSignUp}
                        appName={tenantDetailData.app_name}
                      />
                    )}
                    {screenIndex === 1 && (
                      <PasswordRegStep
                        onSubmit={validateAndCreatePassword}
                        username={basicPayload.business_email as string}
                        init={{ password1: '', password2: basicPayload.company_name || '' }}
                      />
                    )}
                    {screenIndex === 2 && (
                      <SecurityQuestionRegStep
                        onSubmit={createSecurityQuestion}
                        init={{ security_answer: '', security_question: '' }}
                      />
                    )}
                    {screenIndex === 3 && (
                      <PhoneNumberRegStep
                        onSubmit={validateAndCreatePhone}
                        handleSendOTP={sendOTP}
                        init={{ country_isd_code: '', phone: '', otp: '' }}
                      />
                    )}
                    {screenIndex === 4 && <InviteAcceptTerms />}
                  </Grid>
                </Grid>
              </>
            )}
            {!params.key && !params.token && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', marginY: 3 }}>
                      <LinearProgress variant="determinate" value={screenIndex * 12.5} />
                    </Box>
                    {screenIndex === 0 && (
                      <EmailAddressRegStep
                        title={currentUserProps.signUp.title}
                        description={currentUserProps?.signUp.description}
                        onSubmit={validateEmail}
                        policy={currentUserProps.signUp.policy}
                        dataPolicyLink={tenantDetailData?.data_policy_link}
                        init={{
                          email_address: basicPayload.business_email || '',
                          verifyEmail: basicPayload.business_email_verify || '',
                        }}
                      />
                    )}
                    {screenIndex === 1 && (
                      <CompanyLegalNameRegStep
                        onSubmit={validateCompanyName}
                        init={{ company_name: basicPayload.company_name || '' }}
                      />
                    )}
                    {screenIndex === 2 && (
                      <AddressRegStep
                        onSubmit={addAddress}
                        handleCountrySearch={handleCountrySearch}
                        init={{
                          address1: basicPayload.address1 || '',
                          address2: basicPayload.address2 || '',
                          city: basicPayload.city || '',
                          country: basicPayload.country || '',
                          state: basicPayload.state || '',
                          zipcode: basicPayload.zipcode || '',
                        }}
                      />
                    )}
                    {screenIndex === 3 && (
                      <UserNameRegStep
                        onSubmit={validateAndCreateBasicSignUp}
                        init={{
                          first_name: basicPayload.first_name || '',
                          last_name: basicPayload.first_name || '',
                        }}
                      />
                    )}
                    {screenIndex === 4 && (
                      <PasswordRegStep
                        onSubmit={validateAndCreatePassword}
                        username={basicPayload.business_email as string}
                        init={{ password1: '', password2: basicPayload.company_name || '' }}
                      />
                    )}
                    {screenIndex === 5 && (
                      <SecurityQuestionRegStep
                        onSubmit={createSecurityQuestion}
                        init={{
                          security_answer: '',
                          security_question: '',
                        }}
                      />
                    )}
                    {screenIndex === 6 && (
                      <PhoneNumberRegStep
                        onSubmit={validateAndCreatePhone}
                        handleSendOTP={sendOTP}
                        init={{ country_isd_code: '', phone: '', otp: '' }}
                      />
                    )}
                    {screenIndex === 7 && (
                      <TermConditionRegStep
                        updateUserLevel={updateUserLevel}
                        termsAndConditions={tenantDetailData?.terms_and_condition}
                        // privacyPolicy={tenantDetailData?.privacy_policy}
                      />
                    )}
                  </Grid>

                  {screenIndex < 7 && (
                    <Grid item sx={{ marginTop: 2 }} xs={screenIndex >= 5 ? 6 : 12}>
                      <Link
                        color="primary"
                        variant="body2"
                        paddingLeft={1}
                        sx={{ textDecoration: 'none' }}
                        onClick={() => {
                          if (screenIndex === 0) {
                            navigate(PATH_AUTH.login);
                          } else setScreenIndex(screenIndex - 1);
                        }}
                      >
                        Go Back
                      </Link>
                    </Grid>
                  )}
                  {screenIndex >= 5 && (
                    <Grid item sx={{ marginTop: 2 }} xs={6}>
                      <Link
                        color="primary"
                        variant="body2"
                        paddingLeft={1}
                        sx={{ textDecoration: 'none' }}
                        onClick={() => {
                          logout();
                        }}
                      >
                        Do it later
                      </Link>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <StyledLink variant="subtitle1" href={PATH_AUTH.login} paddingLeft={1}>
                      Have an Account?Sign In
                    </StyledLink>
                  </Grid>
                </Grid>
              </>
            )}
          </StyledMainCard>
        </div>
        {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
      </Container>
    </Page>
  );
};

export default SignUp;
