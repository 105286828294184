import { VisibilityOff } from '@mui/icons-material';
import { InputAdornment, Typography, Grid, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CreatePasswordPayload } from 'redux/userManagement/userManagement.type';
import * as Yup from 'yup';
import FormikTextField from 'components/formik/FormikTextField';
import { VALIDATE_PASSWORD } from 'utils/inputValidation';

interface OwnProps {
  onSubmit: (values: CreatePasswordPayload) => void;
  username: string;
  init: CreatePasswordPayload;
}

// TODO add Crowdz password policy
const ValidationSchema = Yup.object().shape({
  password1: Yup.string()
    .required('Required')
    .matches(
      VALIDATE_PASSWORD,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  password2: Yup.string()
    .oneOf([Yup.ref('password1'), null], 'Confirm password must match')
    .required('Required'),
});

const PasswordRegStep = ({ onSubmit, username, init }: OwnProps) => {
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form autoComplete="off" onSubmit={props.handleSubmit}>
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={2}
              >
                Now let's set up a secure password to access your account
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontSize={'12px'}>
                P.S. Your username will be {username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Password</span>}
                fontSize={'13px'}
                name={`password1`}
                component={FormikTextField}
                autoComplete={'off'}
                required
                marginBottom={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <VisibilityOff />
                    </InputAdornment>
                  ),
                }}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                label={
                  <span style={{ color: 'textSecondary', fontSize: '15px' }}>Confirm Password</span>
                }
                fontSize={'13px'}
                name={`password2`}
                component={FormikTextField}
                required
                autoComplete={'off'}
                marginBottom={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <VisibilityOff />
                    </InputAdornment>
                  ),
                }}
                type="password"
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </Grid>
            {/* <Grid item xs={12}>TODO display error 
              <ErrorMessage name="password1" />
            </Grid> */}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRegStep;
