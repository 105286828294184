import Countries from 'config/country.json';
import { FLAG_ICON_FALLBACK } from 'constants/constants';
import { WalletWesternUnionData } from 'redux/bank/bank.type';
import { SupportedCurrencies } from 'redux/common/common.type';
import { RegistrationSteps, CompanyDetail } from 'redux/login/login.type';
import { PATH_AUTH, SETTINGS_PAGES } from 'routes/paths';
import orderBy from 'lodash/orderBy';
import { InvoiceData } from 'redux/invoice/invoice.type';

export const numberCompact = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const monthFormat = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

// TODO clarify the formula
export const formatIARR = (value: number, digits: number) => {
  if (`${value}`.toLowerCase().indexOf('e') !== -1) {
    const tmp = `${value}`.toLowerCase().split('e');
    return `${Number.parseFloat(tmp[0]).toFixed(digits)}${tmp[1]}`;
  }
  return value.toFixed(digits);
};

export const getSupportedFlagIcons = (currency: string[]) =>
  Countries.filter((f) => f.currency && currency.includes(f.currency));
export const getFlag = (currency: string) => Countries.filter((f) => currency === f.currency); // TODO don't use it, use state data

export const addFlagIcons = (currencies: SupportedCurrencies[]) => {
  Array.isArray(currencies) &&
    currencies.forEach((c) => {
      const icon = getFlag(c.alpha_3_code);
      if (icon) c.flagIcon = icon[0]?.flag_1x1;
      else c.flagIcon = FLAG_ICON_FALLBACK;
    });
  return currencies;
};

export const addFlagIconsWallets = (wallets: WalletWesternUnionData[]) => {
  wallets.forEach((c) => {
    const icon = getFlag(c.currency);
    if (icon) c.flag = icon[0].flag_1x1;
    else c.flag = FLAG_ICON_FALLBACK;
  });
  return wallets;
};

// TODO input event, /** @deprecated */ use code instead
export const validateZipCode = (event: KeyboardEvent, country: string) => {
  if (country === 'US') {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  } else {
    return /[a-zA-Z0-9-_ ]/.test(String.fromCharCode(event.keyCode));
  }
};

export const getNotCompletedRegistrationSteps = (steps: RegistrationSteps[]) =>
  steps.filter((item) => item.step_number >= 5 && !item.is_completed);

export const getPostRegistrationSteps = (steps: RegistrationSteps[]) =>
  steps.filter((item) => item.step_number >= 8);
export const isRequiredRegistrationStepsCompleted = (steps: RegistrationSteps[]) =>
  steps.filter((item) => item.step_number <= 7 && !item.is_completed).length === 0;

export const isOnlyUploadInvoice = (steps: RegistrationSteps[]) =>
  steps.length === 1 && steps[0].step_number === 13;

export const isOnlyLegalDocAgreementMissing = (steps: RegistrationSteps[]) => {
  const tmp = steps.filter((f) => !f.is_completed);
  return tmp.length === 1 && tmp[0].step_number === 12;
};

export const isUploadInvoiceAndLegalAgreementOnly = (steps: RegistrationSteps[]) =>
  steps.length === 2 &&
  steps.filter((f) => f.step_number === 12 || f.step_number === 13).length === 2;

export const getRegistrationStepsRoute = (step: number) =>
  ({
    5: PATH_AUTH.postPhoneSetup,
    6: SETTINGS_PAGES.admin,
    7: PATH_AUTH.completeRegTerms,
    8: SETTINGS_PAGES.companyProfile,
    9: SETTINGS_PAGES.userProfile,
    10: SETTINGS_PAGES.bankingInformation,
    11: SETTINGS_PAGES.financialProfile,
    12: PATH_AUTH.acceptSellerTerms, // TODO
    13: SETTINGS_PAGES.accountingSystem,
  }[step]);

export const checkPreRegistrationSteps = (steps: RegistrationSteps[]) =>
  orderBy(steps, ['step_number'], ['asc']).filter((f) => f.step_number <= 7 && !f.is_completed)[0];

export const getQueryOrEmpty = (q?: string) => (q ? q : '');

export const isInvoiceArrayDefinedAndNotEmpty = (args?: InvoiceData) =>
  !!args && Array.isArray(args.results) && args.results.length > 0;

export const isArrayDefinedAndNotEmpty = (args?: any[]) => Array.isArray(args) && args.length > 0;

export const getRegistrationSteps = (data?: RegistrationSteps[]) => {
  if (Array.isArray(data)) {
    const isRequiredCompleted = isRequiredRegistrationStepsCompleted(data);
    if (!isRequiredCompleted) {
      return getNotCompletedRegistrationSteps(data);
    } else {
      const postReg = getPostRegistrationSteps(data);
      return isRequiredCompleted && postReg.filter((f) => !f.is_completed).length > 0
        ? postReg
        : [];
    }
  }
  return [];
};

// TODO check if not using
export const getQueryUserType = (role: string) => {
  if (role === 'seller') {
    return 'buyer';
  } else if (role === 'buyer') {
    return 'Supplier';
  }
};
export const getDashboardTooltip = (companyDetails?: CompanyDetail) => {
  if (
    companyDetails?.invoice_holdback_enabled === true &&
    companyDetails?.invoice_holdback_prorate_enabled === true
  ) {
    return `This offer includes a ${companyDetails?.invoice_holdback_percentage} % performance reserve which will be refunded based on timely repayment. If the repayment is late, the refund will be prorated.`;
  }

  if (companyDetails?.invoice_holdback_enabled === true) {
    return `This offer includes a ${companyDetails?.invoice_holdback_percentage} % performance reserve which will be refunded based on timely repayment.`;
  }
  if (companyDetails?.invoice_holdback_prorate_enabled === true) {
    return 'If the repayment is late, the refund will be prorated.';
  }
};

export const handleOpenMailTo = (supportEmail: string) => {
  const link = document.createElement('a');
  link.href = `mailto:${supportEmail}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isWebsiteHandler = (userInput: string) =>
  /((https?|ftp|smtp|http)?:\/\/(?:www\.|(?!www))[a-zA-Z][a-zA-Z-]+[a-zA-Z]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z]+\.[^\s]{2,})/.test(
    userInput
  );
