import { createSlice } from '@reduxjs/toolkit';
import { UserNotificationState } from '../userNotification/userNotification.type';
import { UserNotificationApi } from 'api';

const initialState: UserNotificationState = {
  isLoading: false,
};

const userNotificationSlice = createSlice({
  name: 'userNotification',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setNotificationError: (state, action) => {
      state.notificationError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserNotificationApi.getNotification.pending, (state) => {
        state.isLoading = true;
        state.userNotifications = undefined;
      })
      .addCase(UserNotificationApi.getNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userNotifications = action.payload;
      })
      .addCase(UserNotificationApi.getNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.notificationError = action.payload;
      })
      .addCase(UserNotificationApi.getNextNotification.pending, (state) => {
        state.isLoading = true;
        state.userNotifications = undefined;
      })
      .addCase(UserNotificationApi.getNextNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userNotifications = action.payload;
      })
      .addCase(UserNotificationApi.getNextNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.notificationError = action.payload;
      })
      .addCase(UserNotificationApi.editAllNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(UserNotificationApi.editAllNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.notificationError = action.payload;
      });
  },
});

export const { setIsLoading, setNotificationError } = userNotificationSlice.actions;

export default userNotificationSlice.reducer;
