import { InputAdornment, Typography, Grid, MenuItem, Button, useMediaQuery } from '@mui/material';
import { Formik, Form, Field, FormikProps } from 'formik';
import { CreatePhoneNumberPayload, SendOTPPayload } from 'redux/userManagement/userManagement.type';
import * as Yup from 'yup';
import countryList from 'country-codes-list';
import ReactPhoneInput from 'react-phone-input-2';
import FormikTextField from 'components/formik/FormikTextField';
import FormikSelect from 'components/formik/FormikSelect';
import { useState } from 'react';

interface OwnProps {
  onSubmit: (values: CreatePhoneNumberPayload) => void;
  handleSendOTP: (phone: SendOTPPayload) => void;
  init: CreatePhoneNumberPayload;
}
// TODO check country code
const ValidationSchema = Yup.object().shape({
  country_isd_code: Yup.string().required('Required'),
  phone: Yup.string().required('Required'), //TODO number
  otp: Yup.string().required('Required'),
});

const PhoneNumberRegStep = ({ handleSendOTP, onSubmit, init }: OwnProps) => {
  const [dialCode, setDialCode] = useState<string>();
  const matches = useMediaQuery('(max-width:599px)');
  return (
    <>
      <Formik
        initialValues={init}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          onSubmit({ ...values, phone: values.phone.replace(dialCode || '', '') });
        }}
      >
        {({ errors, touched, ...others }) => {
          return (
            <Form autoComplete="off" onSubmit={others.handleSubmit}>
              <Grid container sx={{ marginTop: 3 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    fontSize={'16px'}
                    fontWeight={(theme) => theme.typography.fontWeightBold}
                    marginBottom={'30px'}
                  >
                    Let's verify your mobile Phone Number.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container columnSpacing={2} alignItems={'center'}>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        name={`country_isd_code`}
                        label={
                          <span style={{ color: 'textSecondary', fontSize: '15px' }}>Country</span>
                        }
                        fontSize={'16px'}
                        marginBottom={'10px'}
                      >
                        {countryList.all().map((p, index) => (
                          <MenuItem key={index} value={p.countryCode}>
                            {p.countryNameEn}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                      <ReactPhoneInput
                        country={others.values.country_isd_code?.toLocaleLowerCase()}
                        disabled={!others.values.country_isd_code}
                        inputProps={{
                          name: 'phone',
                        }}
                        enableAreaCodes={false}
                        enableAreaCodeStretch={false}
                        onChange={(value, ...other: any) => {
                          if (
                            other[0]?.countryCode?.toUpperCase() !== others.values.country_isd_code
                          ) {
                            console.log('TODO error message, Confirmation modal');
                          } else {
                            others.setFieldValue('phone', value);

                            const x = other[0].format
                              .replace('+', '')
                              .replaceAll('(', '')
                              .replaceAll(')', '')
                              .replaceAll(' ', '');

                            if (x.length === value.length) {
                              setDialCode(other[0].dialCode);
                            } else {
                              if (dialCode) {
                                setDialCode(undefined);
                              }
                            }
                          }
                        }}
                        value={others.values.phone}
                        inputStyle={{ height: '40px', width: '100%' }}
                        dropdownStyle={{ maxHeight: '150px' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          marginTop: matches ? 1 : undefined,
                        }}
                        disabled={
                          !others.values.country_isd_code || !others.values.phone || !dialCode
                        }
                        onClick={() =>
                          handleSendOTP({
                            country_isd_code: others.values.country_isd_code,
                            phone: others.values.phone?.replace(dialCode || '', ''),
                          })
                        }
                      >
                        Send OTP
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    placeholder="Enter One type Passcode"
                    variant="standard"
                    name="otp"
                    required
                    autoComplete={'off'}
                  />
                </Grid>

                <Grid item sx={{ marginTop: 2 }} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      !others.dirty ||
                      !others.values.country_isd_code ||
                      !others.values.phone ||
                      !dialCode
                    }
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PhoneNumberRegStep;
