import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';
import { CommonSuccessResponse } from '../redux/common/common.type';
import {
  BankNotListedResponse,
  BankPaymentPayload,
  ConnectBankAccountPayload,
  ConnectBankAccountResponse,
  ConnectCompanyPayload,
  ManuallyAddBankAccountPayload,
  TransferInternalMoney,
} from '../redux/bank/bank.type';
import {
  BankInformationData,
  PlaidConfiguration,
  WalletWesternUnionData,
  WesternUnionBankMethodData,
  AccountingPlatformData,
  WalletCurrencyListData,
} from '../redux/bank/bank.type';

// TODO review , payload, return value, params
// check the url
const getStripeCompanyAccountPlatform = createAsyncThunk<
  AccountingPlatformData[],
  string,
  { rejectValue: ServerErrors }
>('getCompanyAccountPlatform', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<AccountingPlatformData[]>(
      `${process.env.REACT_APP_SERVER_URL}tpii/accounting/platform/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review , payload, return value, params
const connectCompanyAccountPlatform = createAsyncThunk<
  ConnectBankAccountResponse,
  ConnectBankAccountPayload,
  { rejectValue: ServerErrors }
>('connectCompanyAccountPlatform', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<ConnectBankAccountResponse>(
      `${process.env.REACT_APP_SERVER_URL}tpii/codat/company_connection/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload, check if its exists
const updateWebhook = createAsyncThunk<any, ConnectCompanyPayload, { rejectValue: ServerErrors }>(
  'updateWebhook',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}tpii/codat/webhook/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const connectCompanyWebhook = createAsyncThunk<
  any,
  ConnectCompanyPayload,
  { rejectValue: ServerErrors }
>('connectCompanyWebhook', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}tpii/codat/webhook/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const disconnectACCSystems = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'disconnectACCSystems',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}tpii/codat/company_unlink/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const sendNotListedPlatform = createAsyncThunk<
  BankNotListedResponse,
  { name: string },
  { rejectValue: ServerErrors }
>('sendNotListedPlatform', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<BankNotListedResponse>(
      `${process.env.REACT_APP_SERVER_URL}tpii/save/not_listed_platform/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const getConnectedBankAccountList = createAsyncThunk<
  BankInformationData[],
  string,
  { rejectValue: ServerErrors }
>('getConnectedBankAccountList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<BankInformationData[]>(
      `${process.env.REACT_APP_SERVER_URL}payments/get_bank_informations/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const getGatewayOptions = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getGatewayOptions',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/gateway-options/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
// Old MAnula ACH connect account
const connectAccount = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'connectAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/gateway-options/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const addManualBankAccount = createAsyncThunk<
  CommonSuccessResponse,
  ManuallyAddBankAccountPayload,
  { rejectValue: ServerErrors }
>('addManualBankAccount', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}payments/bank_accounts/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const getPlaidConfiguration = createAsyncThunk<
  PlaidConfiguration,
  void,
  { rejectValue: ServerErrors }
>('getPlaidConfiguration', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<PlaidConfiguration>(
      `${process.env.REACT_APP_SERVER_URL}payments/payment_configuration/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const setDefaultAccount = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'setDefaultAccount',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/payment_account/make_default/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const deleteAccountDetails = createAsyncThunk<
  CommonSuccessResponse,
  string,
  { rejectValue: ServerErrors }
>('deleteAccountDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}payments/delete/payment_account/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const updatePlaidAccount = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'updatePlaidAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/plaid/update/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const getAccessTokenByQuery = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getAccessTokenByQuery',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/plaid/item/token/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const saveEventAudit = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveEventAudit',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/plaid/request_logs/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const savePlaidToken = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'savePlaidToken',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/plaid/access_token/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

//TODO review it, check the payload
const checkBankBalance = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'checkBankBalance',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/get_bank_balance/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getWesternUnionBankingOptions = createAsyncThunk<
  string[],
  void,
  { rejectValue: ServerErrors }
>('getWesternUnionBankingOptions', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<string[]>(
      `${process.env.REACT_APP_SERVER_URL}western_union/banking-options/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const verifyBankAccountApprovalRequest = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'verifyBankAccountApprovalRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/bank_account/approval/request/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const verifyBankAccountApproval = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'verifyBankAccountApproval',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/bank_account/approval/confirmation/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getAuctionPaymentPath = (type: string) => {
  if (type === 'funding') {
    return 'invoice-exchange/dashboard/auction/payments/';
  } else {
    return 'invoice-exchange/dashboard/invoice/payments/';
  }
};
//
// {"receivable_type":"saas_contract","payment_type":"bank_transfer","currency":"USD"}
const verifyManageDeposit = createAsyncThunk<
  any,
  { type: string; payload: BankPaymentPayload },
  { rejectValue: ServerErrors }
>('verifyManageDeposit', async (payload, { rejectWithValue }) => {
  try {
    const { type, ...others } = payload;
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}${getAuctionPaymentPath(type)}`,
      others
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getFundingConfirmationDetails = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'getFundingConfirmationDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const { type, params } = payload;
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}${getAuctionPaymentPath(type)}/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getPaymentAccountDetail = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getPaymentAccountDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/payment_accounts/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getWesternUnionBankMethodData = createAsyncThunk<
  WesternUnionBankMethodData[],
  string,
  { rejectValue: ServerErrors }
>('getWesternUnionBankMethodData', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<WesternUnionBankMethodData[]>(
      `${process.env.REACT_APP_SERVER_URL}western_union/banking-methods/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getWesternUnionBankMethodDataNoDispatch = createAsyncThunk<
  WesternUnionBankMethodData[],
  string,
  { rejectValue: ServerErrors }
>('getWesternUnionBankMethodDataNoDispatch', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<WesternUnionBankMethodData[]>(
      `${process.env.REACT_APP_SERVER_URL}western_union/banking-methods/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check if it uses other  places
const getWalletWesternUnion = createAsyncThunk<
  WalletWesternUnionData[],
  void,
  { rejectValue: ServerErrors }
>('getWalletWesternUnion', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<WalletWesternUnionData[]>(
      `${process.env.REACT_APP_SERVER_URL}western_union/get_wallets/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check if it uses other  places
const addNewWalletWesternUnion = createAsyncThunk<
  WalletWesternUnionData[],
  WalletWesternUnionData,
  { rejectValue: ServerErrors }
>('getWalletWesternUnion', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<WalletWesternUnionData[]>(
      `${process.env.REACT_APP_SERVER_URL}western_union/add_wallet/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check the params
const deleteWalletWesternUnion = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteWalletWesternUnion',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<any>(
        `${process.env.REACT_APP_SERVER_URL}western_union/delete_wallet/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check the params
const transferInternalMoneyWesternUnion = createAsyncThunk<
  TransferInternalMoney,
  any,
  { rejectValue: ServerErrors }
>('transferInternalMoneyWesternUnion', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<TransferInternalMoney>(
      `${process.env.REACT_APP_SERVER_URL}western_union/internal_funds_transfer/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check the params
const getWalletWesternUnionCurrencyList = createAsyncThunk<
  WalletCurrencyListData,
  void,
  { rejectValue: ServerErrors }
>('getWalletWesternUnionCurrencyList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<WalletCurrencyListData>(
      `${process.env.REACT_APP_SERVER_URL}western_union/wallet_currency_list/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getWesternUnionBankMethodData,
  getPaymentAccountDetail,
  getFundingConfirmationDetails,
  verifyManageDeposit,
  verifyBankAccountApproval,
  verifyBankAccountApprovalRequest,
  getWesternUnionBankingOptions,
  checkBankBalance,
  saveEventAudit,
  getAccessTokenByQuery,
  updatePlaidAccount,
  updateWebhook,
  deleteAccountDetails,
  setDefaultAccount,
  getPlaidConfiguration,
  addManualBankAccount,
  connectAccount,
  getGatewayOptions,
  getConnectedBankAccountList,
  getStripeCompanyAccountPlatform,
  sendNotListedPlatform,
  disconnectACCSystems,
  connectCompanyAccountPlatform,
  getWalletWesternUnion,
  deleteWalletWesternUnion,
  transferInternalMoneyWesternUnion,
  getWalletWesternUnionCurrencyList,
  addNewWalletWesternUnion,
  connectCompanyWebhook,
  savePlaidToken,
  getWesternUnionBankMethodDataNoDispatch,
};
