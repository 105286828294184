import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import { BasicSignupPayload } from 'redux/login/login.type';
import Typography from '@mui/material/Typography';
import FormikTextField from 'components/formik/FormikTextField';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { NAME_VALIDATION } from 'utils/inputValidation';

interface OwnProps {
  onSubmit: (values: Partial<BasicSignupPayload>) => void;
  init: Partial<BasicSignupPayload>;
}
const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().matches(NAME_VALIDATION, 'Invalid First name').required('Required'),
  last_name: Yup.string().matches(NAME_VALIDATION, 'Invalid Last name').required('Required'),
});

const UserNameRegStep = ({ onSubmit, init }: OwnProps) => {
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form autoComplete="off" onSubmit={props.handleSubmit}>
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={'30px'}
              >
                What is your name?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                type={'text'}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>First Name</span>}
                fontSize={'13px'}
                name={`first_name`}
                component={FormikTextField}
                required
                autoComplete={'off'}
                marginBottom={4}
              />
              <Field
                type={'text'}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Last Name</span>}
                fontSize={'13px'}
                name={`last_name`}
                autoComplete={'off'}
                required
                component={FormikTextField}
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserNameRegStep;
